import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useProfile, useModal} from "../context";
import Login from "./modals/Login";
import Info from "./modals/Info";
import Prize from "./modals/Prize";
import useEffectOnce from "../hooks/useEffectOnce";
import parse from "html-react-parser";
import CodesWidget from "./codes/codes-widget";
import Aggreement from "./modals/Aggreement";
import Reg from "./modals/Reg";

function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [notShow, setNotShow] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    const {profile, setProfile} = useProfile();

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        if(location.pathname == '/'){
            //document.body.classList.add('overflow');
        }else{
            document.body.classList.remove('overflow');
        }

        setNav(false);
        setLkNav(false);

    }, [location.pathname]);

    useEffect(() => {
        if(!profile.loading && profile.loggedIn){
            if(!profile.loading && profile.data && profile.data.lastPrize){
                show(<Prize prize={profile.data.lastPrize} close={hide}/>, "modal--form modal--prize");
            }
        }
    },[profile.loading, profile.loggedIn]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > window.outerHeight / 3){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(()=>{
        if(!nav){
            document.body.classList.remove('nav-open');
        }else{
            document.body.classList.add('nav-open');
        }
    }, [nav]);

    useEffect(()=>{
        if(!profile.loading && profile.loggedIn){
            if(profile.data.bad){
                show(<Info
                    icon={true}
                    bad={true}
                    title={'Твой аккаунт был заблокирован за&nbsp;нарушение условий акции'}
                    text={'За&nbsp;более подробной информацией обратись в&nbsp;службу поддержки'}
                    close={hide}/>, "modal--form modal--age");
            }

            if(profile.data.profile.rules2 == 0 || profile.data.profile.rules3 == 0){
                show(<Aggreement/>,
                    "modal--reg modal--locked"
                );
            }
        }
    }, [profile.loading, profile.loggedIn]);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    const checkNotification = async() => {
        setLoading(true);
        await fetch(process.env.REACT_APP_API_PROXY + 'checkNotification', {
            method: "POST",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setProfile({
                    ...profile,
                    data: {
                        ...profile.data,
                        notification: [],
                    }
                });

                setNotShow(false);
            }
            setLoading(false);
        });
    }

    const renderHdBtns = () => {
        return(
            <>
                {!profile.loggedIn && (
                    <>
                        <div onClick={()=>{
                            show(<Reg/>, "modal--form modal--reg");
                            setNav(false);
                        }} className="btn-ct btn-ct--filled btn-ct--medium btn-ct--blue d-flex align-items-center justify-content-center">
                            Зарегистрировать чек
                        </div>

                        <div onClick={()=>{
                            show(<Reg/>, "modal--form modal--reg");
                            //show(<Login/>, "modal--form modal--login");
                            setNav(false);
                        }} className="btn-ct btn-ct--border btn-ct--medium d-flex align-items-center justify-content-center">
                            Личный кабинет
                        </div>
                    </>
                )}

                {profile.loggedIn && (
                    <>
                        <CodesWidget extraClasses="btn-ct--filled btn-ct--blue btn-ct--medium" closeNav={()=>{
                            setNav(false);
                        }}/>

                        <Link to="/cabinet" onClick={()=>{
                            setNav(false);
                            if(window.ym) {
                                window.ym(97094979,'reachGoal', 'переход в ЛК');
                            }
                        }} className="btn-ct btn-ct--border btn-ct--medium d-flex align-items-center justify-content-center">
                            Личный кабинет
                        </Link>
                    </>
                )}
            </>
        )
    }

    return (
       <>
           <div id="header"
                className={'header fadeIn animated' +
                    (inner ? ' header--inner' : '') +
                    (nav ? ' active' : '') +
                    (fixed ? ' header--fixed' : '') +
                    (nav ? ' header--open' : '') +
                    (!profile.loading ? ' header--loaded' : '')
                }>
               <div className="container d-flex align-items-center justify-content-between">
                   <a href="https://freshbar.ru/" onClick={()=>{
                       if(window.ym) {
                           window.ym(97094979,'reachGoal', 'переход на основной сайт');
                       }
                   }} target="_blank" className="logo">
                       <div className="logo__img">
                           <img src={require("../assets/img/logo.svg").default}/>
                       </div>
                   </a>

                   <div className={'main__nav fadeIn animated' + (nav ? ' active' : '')}>
                       <div className="main__nav-bl">
                           <div className="main__menu">
                               <ul>
                                   {location.pathname === '/' && (
                                       <>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('promo');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Главная_хед');
                                                   }
                                               }}>Главная</a>
                                           </li>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('steps');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Как участвовать_хэд');
                                                   }
                                               }}>Как участвовать</a>
                                           </li>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('prizes');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Призы_хэд');
                                                   }
                                               }}>Призы</a>
                                           </li>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('products');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Продукты _хэд');
                                                   }
                                               }}>Продукты</a>
                                           </li>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('winners');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Победители_хед');
                                                   }
                                               }}>Победители</a>
                                           </li>
                                           <li>
                                               <a onClick={()=>{
                                                   goToAnchor('faq');
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Вопросы-ответы_хед');
                                                   }
                                               }}>Вопрос-ответ</a>
                                           </li>
                                       </>
                                   )}

                                   {location.pathname !== '/' && (
                                       <>
                                           <li>
                                               <Link to="/" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Главная_хед');
                                                   }
                                               }}>Главная</Link>
                                           </li>
                                           <li>
                                               <Link to="/#steps" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Как участвовать_хэд');
                                                   }
                                               }}>Как участвовать</Link>
                                           </li>
                                           <li>
                                               <Link to="/#prizes" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Призы_хэд');
                                                   }
                                               }}>Призы</Link>
                                           </li>
                                           <li>
                                               <Link to="/#products" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Продукты _хэд');
                                                   }
                                               }}>Продукты</Link>
                                           </li>
                                           <li>
                                               <Link to="/#winners" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Победители_хед');
                                                   }
                                               }}>Победители</Link>
                                           </li>
                                           <li>
                                               <Link to="/#faq" onClick={()=>{
                                                   if(window.ym) {
                                                       window.ym(97094979,'reachGoal', 'Вопросы-ответы_хед');
                                                   }
                                               }}>Вопрос-ответ</Link>
                                           </li>
                                       </>
                                   )}
                               </ul>
                           </div>
                           <div className="main__nav-btns mb-only">
                               {renderHdBtns()}
                           </div>
                       </div>
                   </div>

                   <div className="header__right d-flex align-items-center desk-only">
                       {renderHdBtns()}
                   </div>
                   <div onClick={()=>{
                       setNav(!nav);
                   }} className={'nav__bars mb-only' + (nav ? ' active' : '')}>
                       {!nav && (
                           <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <circle cx="26.0388" cy="25.6764" r="25.5018" fill="#E32213"/>
                               <path d="M15.8858 33.2912H36.1917C36.8897 33.2912 37.4608 32.7201 37.4608 32.0221C37.4608 31.324 36.8897 30.7529 36.1917 30.7529H15.8858C15.1878 30.7529 14.6167 31.324 14.6167 32.0221C14.6167 32.7201 15.1878 33.2912 15.8858 33.2912ZM15.8858 26.9456H36.1917C36.8897 26.9456 37.4608 26.3745 37.4608 25.6765C37.4608 24.9785 36.8897 24.4074 36.1917 24.4074H15.8858C15.1878 24.4074 14.6167 24.9785 14.6167 25.6765C14.6167 26.3745 15.1878 26.9456 15.8858 26.9456ZM14.6167 19.3309C14.6167 20.0289 15.1878 20.6 15.8858 20.6H36.1917C36.8897 20.6 37.4608 20.0289 37.4608 19.3309C37.4608 18.6329 36.8897 18.0618 36.1917 18.0618H15.8858C15.1878 18.0618 14.6167 18.6329 14.6167 19.3309Z" fill="white"/>
                           </svg>

                       )}

                       {nav && (
                           <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <circle cx="26.0388" cy="25.6764" r="24.5018" fill="#E32213" stroke="white" strokeWidth="2"/>
                               <path d="M33.875 17.8138C33.7594 17.6979 33.622 17.606 33.4708 17.5433C33.3196 17.4805 33.1575 17.4482 32.9938 17.4482C32.83 17.4482 32.6679 17.4805 32.5167 17.5433C32.3655 17.606 32.2281 17.6979 32.1125 17.8138L26 23.9138L19.8875 17.8013C19.7718 17.6856 19.6344 17.5938 19.4832 17.5311C19.332 17.4685 19.1699 17.4363 19.0063 17.4363C18.8426 17.4363 18.6805 17.4685 18.5293 17.5311C18.3781 17.5938 18.2407 17.6856 18.125 17.8013C18.0093 17.917 17.9175 18.0544 17.8548 18.2056C17.7922 18.3568 17.76 18.5189 17.76 18.6826C17.76 18.8462 17.7922 19.0083 17.8548 19.1595C17.9175 19.3107 18.0093 19.4481 18.125 19.5638L24.2375 25.6763L18.125 31.7888C18.0093 31.9045 17.9175 32.0419 17.8548 32.1931C17.7922 32.3443 17.76 32.5064 17.76 32.6701C17.76 32.8337 17.7922 32.9958 17.8548 33.147C17.9175 33.2982 18.0093 33.4356 18.125 33.5513C18.2407 33.667 18.3781 33.7588 18.5293 33.8215C18.6805 33.8841 18.8426 33.9163 19.0063 33.9163C19.1699 33.9163 19.332 33.8841 19.4832 33.8215C19.6344 33.7588 19.7718 33.667 19.8875 33.5513L26 27.4388L32.1125 33.5513C32.2282 33.667 32.3656 33.7588 32.5168 33.8215C32.668 33.8841 32.8301 33.9163 32.9938 33.9163C33.1574 33.9163 33.3195 33.8841 33.4707 33.8215C33.6219 33.7588 33.7593 33.667 33.875 33.5513C33.9907 33.4356 34.0825 33.2982 34.1452 33.147C34.2078 32.9958 34.24 32.8337 34.24 32.6701C34.24 32.5064 34.2078 32.3443 34.1452 32.1931C34.0825 32.0419 33.9907 31.9045 33.875 31.7888L27.7625 25.6763L33.875 19.5638C34.35 19.0888 34.35 18.2888 33.875 17.8138Z" fill="white"/>
                           </svg>
                       )}
                   </div>
               </div>
           </div>
       </>
    );
}

export default Header;
