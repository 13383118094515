import { Link } from 'react-router-dom'
import Info from "./Info";
import {useEffect} from "react";
import parse from "html-react-parser";
import {useProfile} from "../../context";

function Prize({prize = null, close}){

    const {profile} = useProfile();

    useEffect(()=>{
        markPrize();
    }, []);

    const markPrize = async () => {
        await fetch(process.env.REACT_APP_API_PROXY + 'markPrize', {
            method: "POST",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {});
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        поздравляем
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle text-center">
                        Ты выиграл приз
                    </div>
                </div>
            </div>
        </>
    );
}

export default Prize;
