import React, {FunctionComponent, Fragment, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {

    useEffect(()=>{
        if(isShown){
            setTimeout(function (){
                if(document.getElementById('modal__content')){
                    document.getElementById('modal__content').scroll(0, 0);
                }
            }, 100);
        }
    }, [isShown]);

    return(
        <Transition appear show={isShown} as={Fragment}>
            {/* Modal backdrop */}
            <Dialog as="div" className={'modal ' + className} onClose={()=>{
                if(!className.includes('modal--locked')) {
                    hide()
                }
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                {/* Modal content */}
                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-200"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    {!className.includes('modal--locked') && (
                                        <div
                                            id="modal-close"
                                            className="modal__close"
                                            onClick={() => {
                                                hide();
                                            }}
                                        >
                                            {className.includes('--contest') && (
                                                <svg width="64" height="36" viewBox="0 0 64 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="1" y="1" width="61.1646" height="33.6414" rx="16.8207" stroke="url(#paint0_radial_142_2975)" stroke-width="2"/>
                                                    <path d="M19 9L44 26" stroke="url(#paint1_radial_142_2975)" stroke-width="2" stroke-linecap="round"/>
                                                    <path d="M44 9L19 26" stroke="url(#paint2_radial_142_2975)" stroke-width="2" stroke-linecap="round"/>
                                                    <defs>
                                                        <radialGradient id="paint0_radial_142_2975" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.5823 17.8207) rotate(90) scale(17.8207 31.5823)">
                                                            <stop stop-color="#EE2323"/>
                                                            <stop offset="1" stop-color="#DD1515"/>
                                                        </radialGradient>
                                                        <radialGradient id="paint1_radial_142_2975" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.5 17.5) rotate(90) scale(8.5 12.5)">
                                                            <stop stop-color="#EE2323"/>
                                                            <stop offset="1" stop-color="#DD1515"/>
                                                        </radialGradient>
                                                        <radialGradient id="paint2_radial_142_2975" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.5 17.5) rotate(90) scale(8.5 12.5)">
                                                            <stop stop-color="#EE2323"/>
                                                            <stop offset="1" stop-color="#DD1515"/>
                                                        </radialGradient>
                                                    </defs>
                                                </svg>
                                            )}
                                            {!className.includes('--contest') && (
                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.15077 16.9854L17.7071 1.42903L16.2929 0.0148171L0.736558 15.5712L2.15077 16.9854ZM17.7071 15.5712L2.15077 0.0148184L0.736558 1.42903L16.2929 16.9854L17.7071 15.5712Z" fill="#A5A5A5"/>
                                                </svg>
                                            )}

                                        </div>
                                    )}

                                    {modalContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
