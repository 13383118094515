import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Reg from "./Reg";
import Pass from "./Pass";
import {useNavigate, useLocation } from "react-router-dom";

function Login(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const profileData =  useProfile();
    const endTime = profileData.endTime;
    let location = useLocation();
    let navigate = useNavigate();
    const { show, hide} = useModal();

    const schema = yup.object({
        login: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        password: yup.string().min(6, '6 символов').required('Некорректный пароль'),
    }).required();

    const { register, setFocus, watch,getValues, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("login");
    const watchPass = watch("password");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("login", data.login);
        formData.append("password", data.password);

        if(localStorage.getItem('click_id')){
            formData.append("click_id", localStorage.getItem('click_id'));
        }

        await fetch(process.env.REACT_APP_API_PROXY + 'authenticateByEmail', {
            method: "POST",
            body: formData,
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                localStorage.removeItem('click_id');
                localStorage.setItem('auth_key', response.data.auth_key);
                window.location.href = process.env.PUBLIC_URL + '/cabinet';
            }else{
                if(response.error.email){
                    setError('login', { type: 'custom', message: response.error.email[0] });
                }

                if(response.error.login){
                    setError('login', { type: 'custom', message: response.error.login[0] });
                }

                if(response.error.password){
                    setError('password', { type: 'custom', message: response.error.password[0]});
                }
            }

            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Вход
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle text-center">
                        авторизация с помощью сервисов
                    </div>
                    <div className="social__links d-flex justify-content-center">
                        <a href={process.env.REACT_APP_SOCIAL + 'eauth?service=vkontakte'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="89" height="61" viewBox="0 0 89 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.00769" y="0.721497" width="87" height="59" rx="29.5" stroke="#A5A5A5"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M60.7275 21.5758C60.9638 20.7951 60.7275 20.2215 59.6027 20.2215H55.883C54.9373 20.2215 54.5012 20.717 54.2648 21.2634C54.2648 21.2634 52.3732 25.8302 49.6936 28.7965C48.8267 29.6552 48.4326 29.9284 47.9597 29.9284C47.7233 29.9284 47.381 29.6552 47.381 28.8746V21.5758C47.381 20.639 47.1065 20.2215 46.3183 20.2215H40.4732C39.8822 20.2215 39.5267 20.6563 39.5267 21.0683C39.5267 21.9564 40.8665 22.1612 41.0046 24.6592V30.0846C41.0046 31.2741 40.7877 31.4898 40.3148 31.4898C39.0538 31.4898 35.9865 26.9027 34.1673 21.6538C33.8108 20.6336 33.4532 20.2215 32.5026 20.2215H28.783C27.7203 20.2215 27.5077 20.717 27.5077 21.2634C27.5077 22.2393 28.7687 27.0792 33.3793 33.4804C36.4529 37.8518 40.7835 40.2215 44.7242 40.2215C47.0886 40.2215 47.3811 39.6952 47.3811 38.7886V35.4847C47.3811 34.432 47.6051 34.2219 48.3538 34.2219C48.9054 34.2219 49.8512 34.4952 52.058 36.6029C54.58 39.1009 54.9958 40.2215 56.4144 40.2215H60.134C61.1968 40.2215 61.7281 39.6952 61.4216 38.6565C61.0862 37.6213 59.882 36.1194 58.2843 34.339C57.4173 33.3242 56.1169 32.2314 55.7228 31.6848C55.1711 30.9824 55.3288 30.67 55.7228 30.0456C55.7228 30.0456 60.2546 23.7225 60.7275 21.5758Z" fill="#5E81B4"/>
                            </svg>
                        </a>
                        {/*<a href={process.env.REACT_APP_SOCIAL + 'eauth?service=odnoklassniki'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="89" height="61" viewBox="0 0 89 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.00769" y="0.721497" width="87" height="59" rx="29.5" stroke="#A5A5A5"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M44.5077 14.2215C40.0894 14.2215 36.5077 17.8032 36.5077 22.2213C36.5077 26.6396 40.0894 30.2215 44.5077 30.2215C48.926 30.2215 52.5077 26.6396 52.5077 22.2213C52.5077 17.8032 48.926 14.2215 44.5077 14.2215ZM44.5077 25.5285C42.6813 25.5285 41.2007 24.0478 41.2007 22.2214C41.2007 20.3951 42.6813 18.9145 44.5077 18.9145C46.3341 18.9145 47.8147 20.3951 47.8147 22.2214C47.8147 24.0478 46.3341 25.5285 44.5077 25.5285ZM47.4647 37.4998C50.6701 36.8308 52.5909 35.2756 52.6926 35.1921C53.6305 34.4215 53.7812 33.0177 53.029 32.0566C52.2769 31.0957 50.9068 30.9413 49.9686 31.7119C49.9488 31.7283 47.9001 33.3384 44.5293 33.3408C41.1586 33.3384 39.0666 31.7283 39.0467 31.7119C38.1086 30.9413 36.7385 31.0957 35.9864 32.0566C35.2342 33.0177 35.3849 34.4215 36.3228 35.1921C36.4258 35.2768 38.4259 36.8724 41.7212 37.5257L37.1287 42.443C36.2941 43.3297 36.3192 44.7417 37.1847 45.5967C37.6072 46.0141 38.1516 46.2215 38.6957 46.2215C39.266 46.2215 39.8359 45.9932 40.263 45.5392L44.5294 40.883L49.2267 45.5694C50.0778 46.44 51.456 46.4386 52.3055 45.5669C53.155 44.6952 53.154 43.283 52.3031 42.4127L47.4647 37.4998Z" fill="#F7931E"/>
                            </svg>
                        </a>*/}
                        <a href={process.env.REACT_APP_SOCIAL + 'eauth?service=yandex'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="89" height="61" viewBox="0 0 89 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.00769" y="0.721497" width="87" height="59" rx="29.5" stroke="#A5A5A5"/>
                                <path d="M52.9251 42.8844H48.5144V20.9845H46.5449C42.9362 20.9845 41.0434 22.8066 41.0434 25.5012C41.0434 28.5616 42.3583 29.9827 45.0531 31.8047L47.2761 33.2965L40.8606 42.8785H36.0903L41.8454 34.3167C38.5314 31.958 36.6682 29.6525 36.6682 25.7549C36.6682 20.8724 40.0587 17.5586 46.5036 17.5586H52.9191V42.8785L52.9251 42.8844Z" fill="#FC3F1D"/>
                            </svg>
                        </a>
                        <a href={process.env.REACT_APP_SOCIAL + 'auth-google'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="89" height="61" viewBox="0 0 89 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.00769" y="0.721497" width="87" height="59" rx="29.5" stroke="#A5A5A5"/>
                                <path d="M40.3407 17.3784C37.0321 18.5104 34.2128 20.9865 32.6425 24.1199C32.0946 25.2008 31.7002 26.3475 31.4592 27.5381C30.8603 30.4889 31.2766 33.6369 32.6352 36.3246C33.5189 38.0777 34.7898 39.6407 36.3237 40.8604C37.7771 42.0144 39.4643 42.8763 41.261 43.351C43.5252 43.9573 45.9355 43.9426 48.2144 43.424C50.2741 42.9493 52.2242 41.9633 53.7799 40.5317C55.4233 39.0198 56.5992 37.0332 57.2201 34.8931C57.8994 32.5632 57.9869 30.0726 57.5633 27.6768C53.3052 27.6768 49.0397 27.6768 44.7815 27.6768C44.7815 29.4444 44.7815 31.212 44.7815 32.9795C47.2502 32.9795 49.719 32.9795 52.1877 32.9795C51.9029 34.674 50.8948 36.2225 49.4707 37.1792C48.5722 37.7855 47.5424 38.1725 46.4761 38.3625C45.4096 38.5451 44.2995 38.567 43.233 38.3552C42.1449 38.1361 41.115 37.6832 40.2093 37.0478C38.7631 36.0325 37.6603 34.5498 37.0978 32.8772C36.5208 31.1754 36.5135 29.2837 37.0978 27.5893C37.5068 26.3914 38.1788 25.2885 39.0699 24.3828C40.1654 23.2581 41.5897 22.4546 43.1309 22.1259C44.4455 21.8483 45.8332 21.8995 47.1188 22.2866C48.2144 22.6152 49.2223 23.2215 50.0477 24.0104C50.8803 23.1776 51.7129 22.345 52.5456 21.5123C52.9839 21.0669 53.444 20.6359 53.8676 20.1757C52.604 19.0072 51.114 18.065 49.4925 17.466C46.5709 16.385 43.2842 16.3631 40.3407 17.3784Z" fill="white"/>
                                <path d="M40.3454 17.3769C43.2816 16.3616 46.5757 16.3836 49.4972 17.4572C51.1187 18.0562 52.6014 18.9911 53.8723 20.1669C53.4487 20.6271 52.9885 21.0581 52.5503 21.5036C51.7177 22.3362 50.8849 23.1688 50.0524 24.0015C49.2271 23.2127 48.2191 22.6138 47.1235 22.2778C45.838 21.8907 44.4503 21.8322 43.1356 22.1171C41.6017 22.4458 40.1775 23.2492 39.0745 24.374C38.1835 25.2724 37.5115 26.3826 37.1025 27.5804C35.6198 26.4264 34.1372 25.2797 32.6472 24.1257C34.2175 20.9851 37.0368 18.509 40.3454 17.3769Z" fill="#EA4335"/>
                                <path d="M31.4636 27.5284C31.7046 26.3451 32.0991 25.1911 32.6469 24.1101C34.1295 25.2641 35.6122 26.4108 37.1023 27.5649C36.518 29.2667 36.518 31.1585 37.1023 32.8529C35.6195 34.0069 34.1368 35.1609 32.6542 36.3077C31.281 33.6271 30.8648 30.4792 31.4636 27.5284Z" fill="#FBBC05"/>
                                <path d="M44.7855 27.6689C49.0437 27.6689 53.3092 27.6689 57.5673 27.6689C57.9909 30.0572 57.8961 32.5479 57.2241 34.8851C56.6032 37.0251 55.4273 39.0119 53.7839 40.5238C52.345 39.4063 50.9061 38.2887 49.4673 37.1713C50.8916 36.2144 51.8996 34.666 52.1844 32.9715C49.7157 32.9715 47.2469 32.9715 44.7782 32.9715C44.7855 31.2039 44.7855 29.4365 44.7855 27.6689Z" fill="#4285F4"/>
                                <path d="M32.6399 36.3157C34.1226 35.169 35.6053 34.015 37.0879 32.861C37.6577 34.5336 38.7533 36.0163 40.1994 37.0316C41.1051 37.667 42.1423 38.1197 43.2233 38.3389C44.2896 38.558 45.3926 38.5289 46.4662 38.3462C47.5326 38.1563 48.5625 37.7692 49.4608 37.163C50.8997 38.2805 52.3385 39.398 53.7774 40.5155C52.2217 41.9543 50.2715 42.9331 48.2118 43.4078C45.933 43.9264 43.5227 43.941 41.2586 43.3348C39.4618 42.8601 37.7745 42.0055 36.3211 40.8442C34.7946 39.6317 33.5237 38.0687 32.6399 36.3157Z" fill="#34A853"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle modal__subtitle--indent text-center">
                        или войти с помощью электронной почты
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__group">
                            <div className="form__list">
                                <div className="row">
                                    <div className="col-12 form__it">
                                        <label htmlFor="login" className="form__label">
                                            E-mail
                                        </label>
                                        <div className={'form__field' + (errors.login ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                            <input
                                                type="text"
                                                id="login"
                                                autoComplete="username"
                                                placeholder="Введите E-mail"
                                                {...register("login")}
                                            />
                                        </div>
                                        {errors.login && (
                                            <p className="error__message">
                                                {errors.login?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 form__it">
                                        <div className="form__label">
                                            Пароль
                                        </div>
                                        <div className={'form__field' + (errors.password ? ' has-error' : '') + ((watchPass && watchPass != '') ? ' has-value' : '')}>
                                            <input
                                                type="password"
                                                id="password"
                                                placeholder="Введите пароль"
                                                autoComplete="current-password"
                                                {...register("password")}
                                            />
                                        </div>
                                        {errors.password && (
                                            <p className="error__message">
                                                {errors.password?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 form__it form__it--action">
                                        <button disabled={isSubmitting} type="submit" className="btn-ct w-100 btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                            <span>Войти</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__ct-box modal__ct-bottom">
                    <div className="modal__form-text">
                        <a onClick={()=>{
                            show(<Pass/>, "modal--form modal--pass");
                        }}>Забыли пароль?</a>
                    </div>
                    {!endTime && (
                        <>
                            <div className="modal__subtitle modal__subtitle--indent2">
                                Если вы&nbsp;впервые на&nbsp;сайте, <br/>
                                то&nbsp;вам необходимо
                            </div>
                            <div className="modal__form-text">
                                <a onClick={()=>{
                                    show(<Reg/>, "modal--form modal--reg");
                                    if(window.ym) {
                                        window.ym(97094979,'reachGoal', 'Регистрация');
                                    }
                                }} >Зарегистрироваться</a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Login;
