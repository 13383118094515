import parse from 'html-react-parser';
import {useProfile} from "../../context";
import {Link, useLocation} from 'react-router-dom'

function PreCheck({close}){

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        Регистрация чека
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="precheck__box">
                        <div className="precheck__box-img">
                            <img src={require("../../assets/img/precheck-img.svg").default}/>
                        </div>
                        <div className="precheck__box-title text-center">
                            Найдите QR&nbsp;код на&nbsp;чеке и&nbsp;наведите на&nbsp;него камеру вашего устройства.
                        </div>
                        <div className="d-flex justify-content-center">
                            <div onClick={()=>{
                                if(window.promoTools) {
                                    window.promoTools.checkGrabberWidget.show();

                                    if(window.ym){
                                        window.ym(97094979,'reachGoal', 'Регистрация чека');
                                    }

                                    close();
                                }
                            }} className="btn-ct btn-ct--white btn-ct--small d-flex align-items-center justify-content-center">
                                Загрузить чек
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PreCheck;
