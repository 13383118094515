import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Login from "./Login";
import Info from "./Info";

function Aggreement(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {profile, setProfile} = useProfile();
    const { show, hide} = useModal();

    const schemaObj = {
        agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true, false], 'Необходимо согласие'),
        agree3: yup.boolean().oneOf([true], 'Необходимо согласие'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchAgree1 = watch("agree1");
    const watchAgree2 = watch("agree2");
    const watchAgree3 = watch("agree3");


    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("rules2", 1);
        if(watchAgree2){
            formData.append("rules1", 1);
        }
        formData.append("rules3", 1);

        await fetch(process.env.REACT_APP_API_PROXY + 'updateProfile', {
            method: "POST",
            body: formData,
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                show(<Info
                        icon={true}
                        text={'Данные профиля успешно обновлены'}
                        close={hide}/>,
                    "modal--info"
                );

                setProfile({
                    ...profile,
                    data: {
                        ...profile.data,
                        rules1: 1,
                        rules2: 1,
                        rules3: 1
                    },
                });
            }

            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Завершение регистрации
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle modal__subtitle--indent text-center">
                        для завершения регистрации, <br/>
                        необходимо ваше согласие
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__group">
                            <div className="form__list">
                                <div className="row">
                                    <div className="col-12 form__it">
                                        <div className="checkbox__it">
                                            <div className={'checkbox__wrap' + (errors.agree2 ? ' has-error' : '')}>
                                                <label className="align-items-start">
                                                    <input
                                                        type="checkbox"
                                                        {...register("agree2")}
                                                    />
                                                    <div className="checkbox__decor"/>
                                                    <div className="checkbox__title text-left">
                                                        Я&nbsp;хочу получать электронную рассылку с&nbsp;<a href="/deliveries_agreement.pdf" target="_blank">рекламными акциями и&nbsp;информационными материалами</a>.
                                                    </div>
                                                </label>
                                            </div>
                                            {errors.agree2 && (
                                                <p className="error__message">
                                                    {errors.agree2?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 form__it">
                                        <div className="checkbox__it">
                                            <div className={'checkbox__wrap' + (errors.agree3 ? ' has-error' : '')}>
                                                <label className="align-items-start">
                                                    <input
                                                        type="checkbox"
                                                        {...register("agree3")}
                                                    />
                                                    <div className="checkbox__decor"/>
                                                    <div className="checkbox__title text-left">
                                                        Я&nbsp;подтверждаю ознакомление и&nbsp;согласие с&nbsp;текстом <a href="/data_processing_agreement.pdf" target="_blank">Согласия на&nbsp;обработку персональных данных</a>.
                                                    </div>
                                                </label>
                                            </div>
                                            {errors.agree3 && (
                                                <p className="error__message">
                                                    {errors.agree3?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 form__it">
                                        <div className="checkbox__it">
                                            <div className={'checkbox__wrap' + (errors.agree1 ? ' has-error' : '')}>
                                                <label className="align-items-start">
                                                    <input
                                                        type="checkbox"
                                                        {...register("agree1")}
                                                    />
                                                    <div className="checkbox__decor"/>
                                                    <div className="checkbox__title checkbox__title--right text-left">
                                                        Я&nbsp;согласен (согласна) с&nbsp;<a href="/rules.pdf" target="_blank">правилами акции</a>.
                                                    </div>
                                                </label>
                                            </div>
                                            {errors.agree1 && (
                                                <p className="error__message">
                                                    {errors.agree1?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 form__it">
                                        <button disabled={isSubmitting || !watchAgree1 || !watchAgree3} type="submit"
                                                className="btn-ct w-100 btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Aggreement;
