import React, {useState} from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import Login from "./Login";
import Reg from "./Reg";

function Pass(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { show, hide} = useModal();

    const schema = yup.object({
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
    }).required();

    const { register, setFocus, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const closeModal = () => {
        hide();
    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);

        await fetch(process.env.REACT_APP_API_PROXY + 'resendPassword', {
            method: "POST",
            body: formData,
            headers:{
                language: process.env.REACT_APP_LANG,
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                show(<Info
                    text={'Письмо с&nbsp;паролем отправлено на&nbsp;' + data.email}
                    close={closeModal}/>,
                    "modal--info"
                );
            }else{
                if(response.error.email){
                    setError('email', { type: 'custom', message: response.error.email });
                }
            }

            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title modal__title--small">
                        Восстановление пароля
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__group">
                            <div className="form__list">
                                <div className="row">
                                    <div className="col-12 form__it">
                                        <label htmlFor="email" className="form__label">
                                            E-mail
                                        </label>
                                        <div
                                            className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                            <input
                                                type="text"
                                                id="email"
                                                placeholder="Введите E-mail"
                                                autoComplete="nope"
                                                {...register("email")}
                                            />

                                        </div>
                                        {errors.email && (
                                            <p className="error__message">
                                                {errors.email?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 form__it">
                                        <button disabled={isSubmitting} type="submit"
                                                className="btn-ct btn-ct--filled btn-ct--red w-100 d-flex align-items-center justify-content-center">
                                            Восстановить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__ct-box modal__ct-bottom">
                    <div className="modal__form-text">
                        <a onClick={()=>{
                            show(<Login/>, "modal--form modal--login");
                        }}>Войти</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Pass;
