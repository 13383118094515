import {useModal, useProfile} from "../context";
import {useEffect} from "react";
function Footer() {
    const { show, hide} = useModal();
    const {profile, setProfile} = useProfile();

    useEffect(()=>{
        if(!profile.isLoading){
            endTime();
        }
    }, [profile.loading]);

    const endTime = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'endTime', {
            method: "GET",
            headers:{
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        })
            .then((response) => response.json())
            .then(response => {
                setProfile({
                    ...profile,
                    isLoading2: false,
                    endTime: response.result
                });
            });
    }

    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="footer__soc-wrap d-flex">
                        <a href="https://vk.com/freshbar" onClick={()=>{
                            if(window.ym) {
                                window.ym(97094979,'reachGoal', 'переход в группу');
                            }
                        }} target="_blank" className="footer__soc">
                            <img src={require("../assets/img/vk.svg").default}/>
                        </a>
                    </div>
                    <div className="footer__legal">
                        Период приобретения товаров с&nbsp;01.06.2024&nbsp;г.&nbsp;по&nbsp;31.08.2024&nbsp;г. включительно в&nbsp;торговых точках на&nbsp;территории Российской Федерации. В&nbsp;акции участвуют любые продукты под товарным знаком Fresh Bar<sup class="reg">&reg;</sup> COLA. Организатором Акции является Общество с&nbsp;ограниченной ответственностью &laquo;Севен&raquo;, ИНН 7725611120, юридический адрес: 117218, г. Москва, ул. Кржижановского, д.15, корп.&nbsp;5, эт.&nbsp;5, пом. 515В, оф.&nbsp;7. Период вручения призов Победителям Акции с&nbsp;01.06.2024&nbsp;г.&nbsp;до&nbsp;31.10.2024&nbsp;г. С&nbsp;информацией об&nbsp;организаторе, правилах Акции и&nbsp;сроках ее&nbsp;проведения, количестве подарков и&nbsp;призов и&nbsp;выигрышей по&nbsp;результатам акции, месте и&nbsp;порядке их&nbsp;получения можно подробнее ознакомиться по&nbsp;адресу freshbarcola.ru. Призы могут отличаться внешне от&nbsp;изображенных на&nbsp;макете. Общий срок проведения акции с&nbsp;01.06.2024 по&nbsp;31.10.2024&nbsp;г. <br/><br/>
                        Erid 2SDnje5TuQD
                    </div>

                </div>
            </div>
        </>
    );
}

export default Footer;
