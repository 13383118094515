import React, {useEffect, useState} from "react";
import {Link, Navigate} from 'react-router-dom'
import {useModal, useProfile} from "../context";
import Info from "../components/modals/Info";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import InputMask from "react-input-mask";
import Loader from "../components/loader";
import CodesWidget from "../components/codes/codes-widget";
import useEffectOnce from "../hooks/useEffectOnce";
import ImgCheck from "../components/modals/ImgCheck";
import Aggreement from "../components/modals/Aggreement";

function Cabinet() {
    const [prizes, setPrizes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitting2, setIsSubmitting2] = useState(false);
    const [isSubmitting3, setIsSubmitting3] = useState(false);
    const [isSubmitting4, setIsSubmitting4] = useState(false);
    const [isSubmitting5, setIsSubmitting5] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [codesType, setCodesType] = useState(0);
    const [tab, setTab] = useState('profile');
    const [feedbacks, setFeedbacks] = useState([]);
    const [fbCur, setFbCur] = useState(-1);
    const [fbPaging, setFbPaging] = useState({
        total: 5,
        current_page: 1
    });
    const [attachFile, setAttachFile] = useState('');

    const [curCode, setCurCode] = useState(0);

    const [quiz, setQuiz] = useState(1);
    const [quizVar, setQuizVar] = useState(null);
    const [quizVarSub, setQuizVarSub] = useState(null);

    const {profile, setProfile} = useProfile();

    const [codes, setCodes] = useState([]);
    const [codesPaging, setCodesPaging] = useState({
        total: 1,
        current_page: 1
    });

    const [receipts, setReceipts] = useState([]);
    const [receiptsPaging, setReceiptsPaging] = useState({
        total: 1,
        current_page: 1
    });

    const [regSugg, setRegSugg] = useState([]);
    const [ctSugg, setCtSugg] = useState([]);
    const [stSugg, setStSugg] = useState([]);
    const [hsSugg, setHsSugg] = useState([]);

    const { show, hide} = useModal();

    useEffectOnce(()=>{
        if(localStorage.getItem('auth_key')){
            getPrizes();
        }

        document.body.classList.remove('nav-open');
    });

    useEffect(()=>{
        getCodes(1);
    }, [codesType]);

    useEffect(()=>{
        if(!profile.isLoading){
            if(profile.data.profile.phone){
                setValue('phone', profile.data.profile.phone);
            }
        }
    }, [profile]);

    useEffect(()=>{
        if(window.location.hash) {
            let anchor = window.location.hash.substr(1);
            setTab(anchor);
        }
    }, []);


    useEffect(() => {
        if(!profile.loading && profile.loggedIn){
            if(profile.data.profile.name){
                setValue2('name', profile.data.profile.name);
            }

            if(profile.data.profile.surname){
                setValue2('sname', profile.data.profile.surname);
            }

            if(profile.data.profile.email){
                setValue2('email', profile.data.profile.email);
            }

            if(profile.data.profile.phone){
                setValue2('phone', profile.data.profile.phone);
            }

            /*if(profile.data.delivery.fio){
                setValue('fio', profile.data.delivery.fio);
            }*/

            if(profile.data.delivery.phone){
                setValue('phone', profile.data.delivery.phone);
            }

            if(profile.data.delivery.zipcode){
                setValue('postal', profile.data.delivery.zipcode);
            }

            if(profile.data.delivery.region){
                setValue('region', profile.data.delivery.region);
            }

            if(profile.data.delivery.city){
                setValue('city', profile.data.delivery.city);
            }

            if(profile.data.delivery.street){
                setValue('street', profile.data.delivery.street);
            }

            if(profile.data.delivery.house){
                setValue('house', profile.data.delivery.house);
            }

            if(profile.data.delivery.flat && profile.data.delivery.flat !== 'undefined'){
                setValue('app', profile.data.delivery.flat);
            }

            if(profile.data.profile.rules2 == 0 || profile.data.profile.rules3 == 0){
                show(<Aggreement/>,
                    "modal--reg modal--locked"
                );
            }
        }
    },[profile]);

    const schema = yup.object({
        city: yup.string().required('Обязательно к заполнению'),
        region: yup.string().required('Обязательно к заполнению'),
        street: yup.string().required('Обязательно к заполнению'),
        postal: yup.string().required('Обязательно к заполнению'),
        house: yup.string().required('Обязательно к заполнению'),
        app: yup.string().nullable(),
        /*agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),*/
    }).required();

    const { register, resetField, watch, getValues, setValue, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    /*const watchFio = watch("fio");
    const watchPhone = watch("phone");*/
    const watchRegion = watch("region");
    const watchCity = watch("city");
    const watchStreet = watch("street");
    const watchPostal = watch("postal");
    const watchHouse = watch("house");
    const watchApp = watch("app");

    const schema2 = yup.object({
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
        name: yup.string().min(2, 'Мин. 2 символа').required('Некорректное Имя'),
        sname: yup.string().min(2, 'Мин. 2 символа').required('Некорректная Фамилия'),
    }).required();

    const { register: register2, setFocus, clearErrors: clearErrors2, setValue: setValue2, watch: watch2, setError: setError2, formState: { errors: errors2, isValid: isValid2 }, handleSubmit: handleSubmit2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const watch2Phone = watch2("phone");
    const watch2Name = watch2("name");
    const watch2SName = watch2("sname");
    const watch2Email = watch2("email");

    const schema3 = yup.object({
        pass1: yup.string().min(6, 'Мин. 6 символов').required('Некорректный пароль'),
        pass2: yup.string().min(6, 'Мин. 6 символов').required(),
        pass3: yup.string().min(6, 'Мин. 6 символов').oneOf([yup.ref('pass2'), null], 'Пароли не совпадают'),
    }).required();

    const {
        register: register3,
        watch: watch3,
        setError: setError3,
        clearErrors: clearErrors3,
        formState: { errors:errors3 },
        handleSubmit: handleSubmit3 } = useForm({
        resolver: yupResolver(schema3)
    });

    const watch3Pass1 = watch3("pass1");
    const watch3Pass2 = watch3("pass2");
    const watch3Pass3 = watch3("pass3");

    const schema4 = yup.object({
        message: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
    }).required();

    const { register: register4, resetField: resetField4,setValue: setValue4, watch: watch4,clearErrors: clearErrors4, setError: setError4, formState: { errors: errors4 }, handleSubmit: handleSubmit4 } = useForm({
        resolver: yupResolver(schema4)
    });

    const watchMess = watch4("message");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        /*formData.append("delivery_fio", data.fio);
        formData.append("delivery_phone", data.phone);*/
        formData.append("zipcode", data.postal);
        formData.append("delivery_city", data.city);
        formData.append("delivery_region", data.region);
        formData.append("delivery_street", data.street);
        formData.append("delivery_house", data.house);
        if(data.app){
            formData.append("delivery_flat", data.app);
        }

        await fetch(process.env.REACT_APP_API_PROXY + 'updateAddress', {
            method: "POST",
            body: formData,
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                show(<Info close={hide} icon={true} text={'Данные для доставки успешно обновлены'}/>, "modal--form modal--info");
                //profileData.onUpdate();
            }else{
                /*if(response.error.delivery_fio){
                    setError('fio', { type: 'custom', message: response.error.delivery_fio[0] });
                }

                if(response.error.delivery_phone){
                    setError('phone', { type: 'custom', message: response.error.delivery_phone[0] });
                }*/

                if(response.error.zipcode){
                    setError('postal', { type: 'custom', message: response.error.zipcode[0] });
                }

                if(response.error.delivery_city){
                    setError('city', { type: 'custom', message: response.error.delivery_city[0] });
                }

                if(response.error.delivery_region){
                    setError('region', { type: 'custom', message: response.error.delivery_region[0] });
                }

                if(response.error.delivery_street){
                    setError('street', { type: 'custom', message: response.error.delivery_street[0] });
                }

                if(response.error.delivery_house){
                    setError('house', { type: 'custom', message: response.error.delivery_house[0] });
                }

                if(response.error.delivery_flat){
                    setError('app', { type: 'custom', message: response.error.delivery_flat[0] });
                }
            }

            setIsSubmitting(false);
        });
    };

    const onSubmit2 = async (data) => {
        setIsSubmitting2(true);
        const formData = new FormData();
        formData.append("phone", data.phone);
        formData.append("name", data.name);
        formData.append("surname", data.sname);
        formData.append("rules2", 1);
        formData.append("rules3", 1);

        await fetch(process.env.REACT_APP_API_PROXY + 'updateProfile', {
            method: "POST",
            body: formData,
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                show(<Info
                        icon={true}
                        text={'Данные профиля успешно обновлены'}
                        close={hide}/>,
                    "modal--info"
                );
                setProfile({
                    ...profile,
                    data: {
                        ...profile.data,
                        profile: {
                            ...profile.data.profile,
                            name: data.name,
                            phone: data.phone,
                            surname: data.sname
                        }
                    }
                });
            }else{
                if(response.error.phone){
                    setError2('phone', { type: 'custom', message: response.error.phone[0] });
                }

                if(response.error.name){
                    setError2('name', { type: 'custom', message: response.error.name[0] });
                }
            }

            setIsSubmitting2(false);
        });
    };

    const onSubmit3 = async (data) => {
        setIsSubmitting3(true);
        const formData = new FormData();
        formData.append("old_password", data.pass1);
        formData.append("pass", data.pass2);
        formData.append("passR", data.pass3);
        formData.append("rules2", 1);
        formData.append("rules3", 1);

        await fetch(process.env.REACT_APP_API_PROXY + 'updateProfile', {
            method: "POST",
            body: formData,
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                show(<Info text={'Пароль успешно обновлен'} icon={true}/>, "modal--form modal--info");
            }else{
                if(response.error.old_password){
                    setError3('pass1', { type: 'custom', message: response.error.old_password[0] });
                }

                if(response.error.pass){
                    setError3('pass2', { type: 'custom', message: response.error.pass[0] });
                }

                if(response.error.passR){
                    setError3('pass3', { type: 'custom', message: response.error.passR[0] });
                }
            }

            setIsSubmitting3(false);
        });
    };

    const getRegion = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: query,
            count: 20,
            from_bound: { value: 'region'},
            to_bound: { value: 'region'}
        };
        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {

            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setRegSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getCity = (query)  => {
        console.log(getValues(['region']));
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues(['region'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'city'},
            to_bound: { value: 'settlement'},
            restrict_value: true
        };

        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setCtSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getStreet = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues(['region'])[0] + ' ' + getValues(['city'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'street'},
            to_bound: { value: 'street'},
            restrict_value: true
        };
        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setStSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getHouse = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues(['region'])[0] + ' ' + getValues(['city'])[0] + ' ' + getValues(['street'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'house'},
            to_bound: { value: 'house'},
            restrict_value: true
        };

        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setHsSugg(responseJson.suggestions);
                }
            }
        };
    }

    const regionSugg = () => {
        if(regSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {regSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue('region', sugg.value);
                                    setRegSugg([]);
                                    resetField('city');
                                    resetField('street');
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');
                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.value}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const streetSugg = () => {
        if(stSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {stSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue('street', sugg.data.street_with_type);

                                    setStSugg([]);
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.data.street_with_type}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const citySugg = () => {
        if(ctSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {ctSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    if(sugg.data.settlement_with_type !== null && sugg.data.settlement_with_type !== undefined) {
                                        setValue('city', sugg.data.settlement_with_type);
                                        if(sugg.data.area_with_type !== null) {
                                            setValue('city', sugg.data.settlement_with_type + ', ' + sugg.data.area_with_type);
                                        }
                                    }else{
                                        setValue('city', sugg.data.city_with_type);
                                    }
                                    if(sugg.data.region_with_type !== null) {
                                        setValue('region', sugg.data.region_with_type);
                                    }

                                    setCtSugg([]);
                                    resetField('street');
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {(sugg.data.settlement_with_type !== null && sugg.data.settlement_with_type !== undefined) ? sugg.data.settlement_with_type : sugg.data.city_with_type}
                                    {(sugg.data.area_with_type !== null) ? ', ' + sugg.data.area_with_type : ''}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const houseSugg = () => {
        if(hsSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {hsSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue('house', sugg.data.house + ' ' + ((sugg.data.block !== null) ? ((sugg.data.block_type !== null) ? sugg.data.block_type + ' ' + sugg.data.block : ' ' + sugg.data.block) : ''));
                                    setValue('postal', sugg.data.postal_code);
                                    setHsSugg([]);
                                    resetField('app');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.data.house + ' ' + ((sugg.data.block !== null) ? ((sugg.data.block_type !== null) ? sugg.data.block_type + ' ' + sugg.data.block : ' ' + sugg.data.block) : '')}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const getCodes = async(page = 1) => {
        setLoading2(true);
        await fetch(process.env.REACT_APP_API_PROXY + 'getCodes?page=' + page + '&type=' + codesType, {
            method: "GET",
            headers: {
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setCodes(response.data.rows);
                setCodesPaging(response.data.paging);
                setLoading2(false);

                if(page !== 1){
                    goToAnchor('codes');
                }
            }
        });
    }

    const getPrizes = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'getPrizes', {
            method: "GET",
            headers: {
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setPrizes(response.data);
            }
        });
    }

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }

    const pagination = (c, m) => {
        let current = c,
            last = m,
            delta = 1,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(codesPaging.current_page), codesPaging.total);
        if(codesPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(codesPaging.current_page !== 1){
                            getCodes(parseInt(codesPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((codesPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 24L11.5 15.5L19.5 7" stroke="#390E00" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    getCodes(parseInt(page))
                                }} className={'pager__item ' + ((page == codesPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(codesPaging.total !== codesPaging.current_page){
                            getCodes(parseInt(codesPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((codesPaging.total === codesPaging.current_page) ? 'disabled' : '')}>
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 24L21.5 15.5L13.5 7" stroke="#390E00" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    const renderStatus = (prize) => {
        switch(prize.type){
           case 'defeat':
                return(
                    <div className="d-flex align-items-center">
                        <i className="icon__defeat"/>
                        <span>{prize.text}</span>
                    </div>
                )
                break;
            case 'wait':
                return(
                    <div className="d-flex align-items-center">
                        <i className="icon__wait"/>
                        <span>{prize.text}</span>
                    </div>
                )
                break;
            case 'win_phone':
                return(
                    <div className="d-flex align-items-center">
                        <span>{prize.text}</span>
                    </div>
                )
                break;
            case 'wait_phone_bank':
                return(
                    <div className="d-flex align-items-center mb-flex-wrap">
                        <span className="mr-4">{prize.prize}</span> <div className="mb-only w-100"/>
                        <a onClick={()=>{
                            setTab('profile');
                        }}>{prize.text}</a>
                    </div>
                )
                break;
            case 'win':
                return(
                    <div className="d-flex align-items-center mb-flex-wrap"> <div className="mb-only w-100"/>
                        {(!profile.data.delivery.city || !profile.data.delivery.fio) && (
                            <>
                                <span className="mr-4">{prize.text}</span>
                                <a onClick={()=>{
                                    setTab('delivery');
                                }}>заполните данные</a>
                            </>
                        )}
                    </div>
                )
                break;
            case 'win_sticker':
                return(
                    <div className="d-flex align-items-center">
                        <a href="https://t.me/addstickers/twix_hotbev" target="_blank">{prize.text}</a>
                    </div>
                )
                break;
            default:
                return(
                    <div className="d-flex align-items-center">
                        <span>&mdash;</span>
                    </div>
                )
                break;
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/" replace />)
    }

    return (
        <div className="content content--lk">
            <div className="sc__lk">
                <div className="container">
                    {profile.isLoading && (
                        <Loader/>
                    )}
                    {!profile.isLoading && profile.loggedIn && (
                        <div className="lk__wrap d-flex align-items-start fadeIn animated">
                            <div className="back__link">
                                <Link to="/" className="btn-ct btn-ct--medium btn-ct--filled btn-ct--red d-flex align-items-center">На главную</Link>
                            </div>
                            <div className="lk__left">
                                <div className="lk__box">
                                    <div className="lk__nav-box lk__nav-box--user">
                                        <div className="lk__user-icon">
                                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="50" height="50" rx="25" stroke="#040429" strokeWidth="2"/>
                                                <path d="M25.9997 27.7333C29.3364 27.7333 32.0664 24.9253 32.0664 21.4933V18.3733C32.0664 14.9413 29.3364 12.1333 25.9997 12.1333C22.6631 12.1333 19.9331 14.9413 19.9331 18.3733V21.4933C19.9331 24.9253 22.6631 27.7333 25.9997 27.7333Z" fill="#040429"/>
                                                <path d="M25.9995 43.3331C32.7006 43.3331 38.1329 40.1902 38.1329 36.3131C38.1329 32.4361 32.7006 29.2931 25.9995 29.2931C19.2985 29.2931 13.8662 32.4361 13.8662 36.3131C13.8662 40.1902 19.2985 43.3331 25.9995 43.3331Z" fill="#040429"/>
                                            </svg>
                                        </div>
                                        <div className="lk__nav-name">
                                            {profile.data.profile.name + ' ' + (profile.data.profile.surname ? profile.data.profile.surname : '')}
                                        </div>
                                    </div>
                                    <div className="lk__tabs-scroll">
                                        <div className="lk__tabs-list">
                                            <div onClick={()=>{
                                                setTab('profile');
                                                goToAnchor('lk-right');
                                            }} className={'lk__nav-box lk__nav-box--nav d-flex align-items-center' + (tab === 'profile' ? ' active' : '')}>
                                                Мои данные
                                            </div>
                                            <div onClick={()=>{
                                                setTab('codes');
                                                goToAnchor('lk-right');
                                            }} className={'lk__nav-box lk__nav-box--nav d-flex align-items-center' + (tab === 'codes' ? ' active' : '')}>
                                                История участия
                                            </div>
                                            <div onClick={()=>{
                                                setTab('prizes');
                                                goToAnchor('lk-right');
                                                if(window.ym) {
                                                    window.ym(97094979,'reachGoal', 'Переход в Мои призы');
                                                }
                                            }} className={'lk__nav-box lk__nav-box--nav d-flex align-items-center' + (tab === 'prizes' ? ' active' : '')}>
                                                Призы
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={()=>{
                                        localStorage.removeItem('auth_key');
                                        window.location.reload();
                                    }} className="lk__nav-box lk__nav-box--nav d-flex align-items-center">
                                        Выйти
                                    </div>
                                </div>
                            </div>
                            <div id="lk-right" className="lk__right">
                                <div id="profile" className={'fadeIn animated lk__tabs-pane' + (tab === 'profile' ? ' active' : '')}>
                                    <div className="lk__tab-title">
                                        Персональные данные
                                    </div>
                                    <div className="lk__box">
                                        <div className="lk__box-group">
                                            <div className="lk__box-group-title">
                                                Изменить данные профиля
                                            </div>
                                            <form id="profile-form" autoComplete="off" onSubmit={handleSubmit2(onSubmit2)} className={(isSubmitting2 ? ' form--loading' : '')}>
                                                <div className="form__group">
                                                    <div className="form__list">
                                                        <div className="row">
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="name" className="form__label">
                                                                    Имя
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors2.name ? ' has-error' : '') + ((watch2Name && watch2Name != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="text"
                                                                        id="name"
                                                                        {...register2("name")}
                                                                    />
                                                                </div>
                                                                {errors2.name && (
                                                                    <p className="error__message">
                                                                        {errors2.name?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="sname" className="form__label">
                                                                    Фамилия
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors2.sname ? ' has-error' : '') + ((watch2SName && watch2SName != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="text"
                                                                        id="sname"
                                                                        {...register2("sname")}
                                                                    />
                                                                </div>
                                                                {errors2.sname && (
                                                                    <p className="error__message">
                                                                        {errors2.sname?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="email" className="form__label">
                                                                    E-mail
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors2.email ? ' has-error' : '') + ((watch2Email && watch2Email != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="text"
                                                                        id="email"
                                                                        disabled
                                                                        {...register2("email")}
                                                                    />
                                                                </div>
                                                                {errors2.email && (
                                                                    <p className="error__message">
                                                                        {errors2.email?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="phone2" className="form__label">
                                                                    Телефон
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors2.phone ? ' has-error' : '') + ((watch2Phone && watch2Phone != '') ? ' has-value' : '')}>
                                                                    <InputMask
                                                                        autoComplete="nope"
                                                                        type="text"
                                                                        id="phone2"
                                                                        mask="+7 999-999-99-99"
                                                                        alwaysShowMask={false}
                                                                        {...register2("phone", {
                                                                            onBlur: (e) => {
                                                                                const val_length_without_dashes = watch2Phone.replace('+', '').replace(/-|_| /g, "").length;
                                                                                if (val_length_without_dashes < 11) {
                                                                                    setValue2('phone', '');
                                                                                }
                                                                            }
                                                                        })}
                                                                    />

                                                                </div>
                                                                {errors2.phone && (
                                                                    <p className="error__message">
                                                                        {errors2.phone?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {!profile.data.bad && (
                                                                <div
                                                                    className="col-6 form__it form__it--action d-flex">
                                                                    <button id="profile-form-submit" disabled={isSubmitting2} type="submit"
                                                                            className="btn-ct w-100 btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                                                        <span>Сохранить</span>
                                                                    </button>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="lk__box-group">
                                            <div className="lk__box-group-title">
                                                Изменить пароль
                                            </div>
                                            <form id="pass-form" autoComplete="off" onSubmit={handleSubmit3(onSubmit3)} className={'modal__form' + (isSubmitting3 ? ' form--loading' : '')}>
                                                <div className="form__group">
                                                    <div className="form__list">
                                                        <div className="row">
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="pass1" className="form__label">
                                                                    Текущий пароль
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors3.pass1 ? ' has-error' : '') + ((watch3Pass1 && watch3Pass1 != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="password"
                                                                        id="pass1"
                                                                        autoComplete="current-password"
                                                                        {...register3("pass1")}
                                                                    />
                                                                </div>
                                                                {errors3.pass1 && (
                                                                    <p className="error__message">
                                                                        {errors3.pass1?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="pass2" className="form__label">
                                                                    Новый пароль
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors3.pass2 ? ' has-error' : '') + ((watch3Pass2 && watch3Pass2 != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="password"
                                                                        id="pass2"
                                                                        autoComplete="current-password"
                                                                        {...register3("pass2")}
                                                                    />
                                                                </div>
                                                                {errors3.pass2 && (
                                                                    <p className="error__message">
                                                                        {errors3.pass2?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="col-6 form__it">
                                                                <label htmlFor="pass3" className="form__label">
                                                                    Повтор нового пароля
                                                                </label>
                                                                <div
                                                                    className={'form__field' + (errors3.pass3 ? ' has-error' : '') + ((watch3Pass3 && watch3Pass3 != '') ? ' has-value' : '')}>
                                                                    <input
                                                                        type="password"
                                                                        id="pass3"
                                                                        autoComplete="current-password"
                                                                        {...register3("pass3")}
                                                                    />
                                                                </div>
                                                                {errors3.pass3 && (
                                                                    <p className="error__message">
                                                                        {errors3.pass3?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {!profile.data.bad && (
                                                                <div
                                                                    className="col-6 form__it">
                                                                    <label className="form__label form__label--hidden desk-only">
                                                                        Изменить
                                                                    </label>
                                                                    <button disabled={isSubmitting3} type="submit"
                                                                            className="btn-ct w-100 btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                                                        <span>Изменить</span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="lk__box-group">
                                            <div className="lk__box-group-title">
                                                Реквизиты для доставки призов
                                            </div>
                                            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                                                  className={'delivery__form' + (isSubmitting ? ' form--loading' : '')}>
                                                <div className="form__list">
                                                    <div className="row">
                                                        <div className="col-6 col-mb-12 form__it">
                                                            <label htmlFor="region" className="form__label">
                                                                Область
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.region ? ' has-error' : '') + ((watchRegion && watchRegion != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="region"
                                                                    {...register("region", {
                                                                        onChange: (region) => {
                                                                            getRegion(region.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                {regionSugg()}
                                                            </div>
                                                            {errors.region && (
                                                                <p className="error__message">
                                                                    {errors.region?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-mb-12 form__it">
                                                            <label htmlFor="city" className="form__label">
                                                                Населенный пункт
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.city ? ' has-error' : '') + ((watchCity && watchCity != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="city"
                                                                    {...register("city", {
                                                                        onChange: (city) => {
                                                                            getCity(city.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                {citySugg()}
                                                            </div>
                                                            {errors.city && (
                                                                <p className="error__message">
                                                                    {errors.city?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-6 form__it">
                                                            <label htmlFor="street" className="form__label">
                                                                Улица
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.street ? ' has-error' : '') + ((watchStreet && watchStreet != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="street"
                                                                    {...register("street", {
                                                                        onChange: (street) => {
                                                                            getStreet(street.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                {streetSugg()}
                                                            </div>
                                                            {errors.street && (
                                                                <p className="error__message">
                                                                    {errors.street?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-mb-12 form__it">
                                                            <label htmlFor="postal" className="form__label">
                                                                Индекс
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.postal ? ' has-error' : '') + ((watchPostal && watchPostal != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="postal"
                                                                    {...register("postal")}
                                                                />
                                                            </div>
                                                            {errors.postal && (
                                                                <p className="error__message">
                                                                    {errors.postal?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-4 col-mb-6 form__it">
                                                            <label htmlFor="house" className="form__label">
                                                                Номер дома *
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.house ? ' has-error' : '') + ((watchHouse && watchHouse != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="house"
                                                                    {...register("house", {
                                                                        onChange: (house) => {
                                                                            getHouse(house.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                {houseSugg()}
                                                            </div>
                                                            {errors.house && (
                                                                <p className="error__message">
                                                                    {errors.house?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-4 col-mb-6 form__it">
                                                            <label htmlFor="app" className="form__label">
                                                                Квартира
                                                            </label>
                                                            <div
                                                                className={'form__field' + (errors.app ? ' has-error' : '') + ((watchApp && watchApp != '') ? ' has-value' : '')}>
                                                                <input
                                                                    type="text"
                                                                    id="app"
                                                                    {...register("app")}
                                                                />
                                                            </div>
                                                            {errors.app && (
                                                                <p className="error__message">
                                                                    {errors.app?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-4 col-mb-12 form__it">
                                                            <label className="form__label form__label--hidden desk-only">
                                                                Cохранить
                                                            </label>
                                                            <button type="submit"
                                                                    className="w-100 btn-ct btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                                                <span>сохранить</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div id="codes" className={'fadeIn animated lk__tabs-pane' + (tab === 'codes' ? ' active' : '')}>
                                    <div className="lk__tab-title">
                                        История чеков
                                    </div>

                                    <div className="lk__box">
                                        <div className="lk__codes-header">
                                            <div className="codes__mode-list d-flex align-items-center">
                                                <div onClick={()=>{
                                                    setCodesType(0);
                                                }} className={'codes__mode-it' + (codesType === 0 ? ' active' : '')}>
                                                    Все
                                                </div>
                                                <div onClick={()=>{
                                                    setCodesType(1);
                                                }} className={'codes__mode-it' + (codesType === 1 ? ' active' : '')}>
                                                    Принятые
                                                </div>
                                                <div onClick={()=>{
                                                    setCodesType(2);
                                                }} className={'codes__mode-it' + (codesType === 2 ? ' active' : '')}>
                                                    Отклоненные
                                                </div>
                                            </div>
                                        </div>
                                        {!loading2 && codes.length === 0 && (
                                            <div className="empty__wrap">
                                                <div className="empty__title text-center">
                                                    Пока нет <br className="mb-only"/>загруженных чеков
                                                </div>
                                            </div>
                                        )}

                                        {codes.length > 0 && (
                                            <div className={'tb tb--codes' + (loading2 ? ' tb--loading' : '')}>
                                                {
                                                    codes.map((code, i) => (
                                                        <div className="codes__it d-flex align-items-center">
                                                            <div className="codes__it-col">
                                                                <div className="codes__it-data">
                                                                    {code.date_create}
                                                                </div>
                                                                <div className="codes__it-small">
                                                                    {code.check_sum} руб.
                                                                </div>
                                                            </div>
                                                            <div className="codes__it-col">
                                                                <div className={'codes__it-data' + ' codes__status--' + code.status.type}>
                                                                    {code.status.text}
                                                                </div>
                                                                {code.status.type === 'bad' && code.status.rejectReason && (
                                                                    <div className="codes__it-small codes__it-small--bad">
                                                                        {code.status.rejectReason}
                                                                    </div>
                                                                )}

                                                            </div>
                                                            <div className="codes__it-col">
                                                                <div className="codes__details">
                                                                    <a onClick={()=>{
                                                                        show(<ImgCheck
                                                                            close={hide}
                                                                            img={code.img}
                                                                            check_fn={code.check_fn}
                                                                            check_fd={code.check_fd}
                                                                            check_fp={code.check_fp}
                                                                        />, "modal--form modal--img");
                                                                    }}>Посмотреть данные чека</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}

                                        {codesPaging.total > 1 && (
                                            renderPager()
                                        )}
                                    </div>
                                </div>
                                <div id="prizes" className={'fadeIn animated lk__tabs-pane' + (tab === 'prizes' ? ' active' : '')}>
                                    <div className="lk__tab-title">
                                        Призы
                                    </div>
                                    <div className="lk__box">
                                        <div className="lk__box-group lk__box-group--prizes">
                                            <div className="prizes__list">
                                                <div className="row justify-content-center">
                                                    <div className={'col-4 prizes__it lk__pr-item' + (prizes && prizes.includes(-1) ? ' active' : '')}>
                                                        <a onClick={()=>{
                                                            if(window.ym) {
                                                                window.ym(97094979,'reachGoal', 'получить приз 1');
                                                            }
                                                        }} href={((prizes && prizes.includes(-1)) ? 'https://t.me/addstickers/colaoriginal' : '#')} target="_blank" className="lk__pr-item-bl">
                                                            <div className="prizes__it-img">
                                                                <div className="prize__img prize__img--1">
                                                                    <img src={require("../assets/img/pr-1.png")}/>
                                                                </div>
                                                            </div>
                                                            <div className="prizes__it-lbl">
                                                                гарантированно
                                                            </div>
                                                            <div className="prizes__it-title">
                                                                Стикер-пак под <br/>
                                                                вкус колы
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className={'col-4 prizes__it lk__pr-item' + (prizes && prizes.includes(-2) ? ' active' : '')}>
                                                        <a onClick={()=>{
                                                            if(window.ym) {
                                                                window.ym(97094979,'reachGoal', 'получить приз 2');
                                                            }
                                                        }} href={((prizes && prizes.includes(-2)) ? 'https://t.me/addstickers/colabanana' : '#')} target="_blank" className="lk__pr-item-bl">
                                                            <div className="prizes__it-img">
                                                                <div className="prize__img prize__img--1">
                                                                    <img src={require("../assets/img/pr-1.png")}/>
                                                                </div>
                                                            </div>
                                                            <div className="prizes__it-lbl">
                                                                гарантированно
                                                            </div>
                                                            <div className="prizes__it-title">
                                                                Стикер-пак под <br/>
                                                                вкус колы
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className={'col-4 prizes__it lk__pr-item' + (prizes && prizes.includes(-3) ? ' active' : '')}>
                                                        <a onClick={()=>{
                                                            if(window.ym) {
                                                                window.ym(97094979,'reachGoal', 'получить приз 3');
                                                            }
                                                        }} href={((prizes && prizes.includes(-2)) ? 'https://t.me/addstickers/cola_vanilla' : '#')} target="_blank" className="lk__pr-item-bl">
                                                            <div className="prizes__it-img">
                                                                <div className="prize__img prize__img--1">
                                                                    <img src={require("../assets/img/pr-1.png")}/>
                                                                </div>
                                                            </div>
                                                            <div className="prizes__it-lbl">
                                                                гарантированно
                                                            </div>
                                                            <div className="prizes__it-title">
                                                                Стикер-пак под <br/>
                                                                вкус колы
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className={'col-4 prizes__it lk__pr-item' + (prizes && [1, 2, 3].some(el => prizes.includes(el)) ? ' active' : '')}>
                                                        <div className="prizes__it-img">
                                                            <div className="prize__img prize__img--2">
                                                                <img src={require("../assets/img/pr-2.png")}/>
                                                            </div>
                                                        </div>
                                                        <div className="prizes__it-lbl">
                                                            каждую неделю
                                                        </div>
                                                        <div className="prizes__it-title">
                                                            Много яркого <br/>
                                                            мерча
                                                        </div>
                                                    </div>
                                                    <div className={'col-4 prizes__it prizes__it--main lk__pr-item' + (prizes && prizes.includes(-1) ? ' active' : '')}>
                                                        <div className="prizes__it-img">
                                                            <div className="prize__img prize__img--3">
                                                                <img src={require("../assets/img/pr-3.png")}/>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="prizes__it-lbl">
                                                                главный приз
                                                            </div>
                                                        </div>
                                                        <div className="prizes__it-title">
                                                            <span>Не по правилам</span> <br/>
                                                            много <span>денег</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <CodesWidget extraClasses="btn-ct--filled btn-ct--red btn-ct--large"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="hidden">
                <div id="update-codes" onClick={()=>{
                    getCodes(1);
                }}></div>
                <div id="codes-tab" onClick={()=>{
                    setTab('codes');
                }}></div>
            </div>
        </div>
    );
}

export default Cabinet;
