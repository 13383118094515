import Header from "../components/Header";
import {Link} from "react-router-dom";
import useEffectOnce from "../hooks/useEffectOnce";

function PageNotFound() {
    useEffectOnce(()=>{
        document.body.classList.remove('nav-open');
    });

    return(
        <>
            <Header/>
            <div className="sc__404">
                <div className="container">
                    <div className="sc__404-ct">
                        <div className="sc__404-title text-center">
                            404
                        </div>
                        <div className="sc__404-subtitle text-center">
                            Страница не найдена
                        </div>
                        <div className="d-flex justify-content-center">
                            <Link to="/" className="btn-ct btn-ct--filled btn-ct--red d-flex align-items-center justify-content-center">
                                На главную
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;