import React, {createContext, useContext, useState, useMemo, useEffect} from 'react';
import useEffectOnce from "../hooks/useEffectOnce";
import {useModal} from "./ModalContext";
import Aggreement from "../components/modals/Aggreement";
import Info from "../components/modals/Info";

const profileContextDefaultValues = {
    data: {},
    isLoading: true,
    isLoading2: true,
    loggedIn: false,
    endTime: false
};

const ProfileContext = createContext({
    profileContextDefaultValues,
    setProfile: () => {}
});

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(profileContextDefaultValues);
    const value = useMemo(
        () => ({ profile, setProfile }),
        [profile]
    );

    const {show, hide} = useModal();

    useEffect(()=>{
        console.log(profile);
    }, [profile]);

   useEffectOnce(()=>{
       if(localStorage.getItem('auth_key')){
           getProfile();
       }else{
           resetProfileData();
       }
   });

    const resetProfileData = () => {
        setProfile({
            data: {},
            isLoading: false,
            isLoading2: false,
            loggedIn: false,
            endTime: false
        });
    }

    const getProfile = async(auth_key = null) => {
        await fetch(process.env.REACT_APP_API_PROXY + 'getProfile', {
            method: "GET",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key') ?? auth_key,
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        })
            .then((response) => response.json())
            .then(response => {
                if(response.result){
                    setProfile({
                        ...profile,
                        data: response.data,
                        isLoading: false,
                        loggedIn: true,
                    });

                    if(localStorage.getItem('network')){
                        switch(localStorage.getItem('network')){
                            case 'go':
                                if(window.ym){
                                    window.ym(97094979,'reachGoal','Успешная регистрация через Гугл');
                                    localStorage.removeItem('network');
                                }
                                break;
                            case 'vk':
                                if(window.ym){
                                    window.ym(97094979,'reachGoal','Успешная регистрация через ВК');
                                    localStorage.removeItem('network');
                                }
                                break;
                            case 'ya':
                                if(window.ym){
                                    window.ym(97094979,'reachGoal','Успешная регистрация через Яндекс');
                                    localStorage.removeItem('network');
                                }
                                break;
                        }
                    }

                    if(window.promoTools){
                        window.promoTools.checkGrabberWidget.settings = {
                            skin: 'custom',
                            api: process.env.REACT_APP_API_PROXY,
                            method: 'widgetsReceipts',
                            userUuid: response.data.profile.userUuid,
                            i18n: {
                                manual: {
                                    title: 'ввести данные <br/><span>с чека вручную</span>',
                                    subTitle: '',
                                },
                                photos: {
                                    title: 'загрузка <span>чека</span>',
                                    subTitle: 'Добавьте фото чека',
                                    btnPhotos: 'Отправить',
                                },
                                pending: {
                                    title: 'Подождите!',
                                    subTitle: 'Идет отправка чека',
                                },
                                fail: {
                                    title: 'Не удалось <br/> <span>отправить чек!</span>',
                                },
                                success: {
                                    title: 'Спасибо за&nbsp;чек',
                                    subTitle: 'Ваш чек обрабатывается до&nbsp;5-ти рабочих дней. Узнать статус чека можно в&nbsp;личном кабинете.',
                                },
                            },
                            events: {
                                onSentSuccess(res) {
                                    let el = document.getElementById('update-codes');
                                    if(el){
                                        el.click();
                                    }

                                    if(window.ym){
                                        window.ym(97094979,'reachGoal', 'Успешная регистрация чека');
                                    }
                                },
                            },
                        };
                    }
                }else{
                    resetProfileData();
                    localStorage.removeItem('auth_key');
                    window.location.reload();
                }

            });
    }

  return (
    <ProfileContext.Provider value={value}>
        {useMemo(() => (
            children
        ), [])}
    </ProfileContext.Provider>
  )
}

export function useProfile() {
  return useContext(ProfileContext)
}