import React, {useEffect, useLayoutEffect, useState} from "react";
import parse from "html-react-parser";
import {useModal, useProfile} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { ScrollPercentage } from 'react-scroll-percentage'
import {yupResolver} from "@hookform/resolvers/yup";
import CustomScroll from 'react-custom-scroll';
import Reg from "../components/modals/Reg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CodesWidget from "../components/codes/codes-widget";
import Info from "../components/modals/Info";
import Contest from "../components/modals/Contest";

let depths = {
    products: false,
    25: false,
    50: false,
    75: false,
    100: false
};

const faq = [
    {
        title: 'Где я&nbsp;могу найти полную информацию об&nbsp;акции?',
        answer: 'Информацию об&nbsp;Акции, а&nbsp;также полную информацию об&nbsp;организаторе Акции, правилах проведения Акции, порядке определения победителей Акции, количестве подарков и&nbsp;призов Акции, сроках, месте и&nbsp;порядке их&nbsp;получения можно узнать в&nbsp;сети Интернет по&nbsp;адресу www.freshbarcola.ru.'
    },
    {
        title: 'Кто может участвовать в&nbsp;акции?',
        answer: 'К&nbsp;участию в&nbsp;акции допускаются дееспособные лица, граждане Российской Федерации, достигшие 18&nbsp;лет, постоянно проживающие на&nbsp;территории РФ.'
    },
    {
        title: 'Если я&nbsp;являюсь гражданином другой страны, но&nbsp;проживаю на&nbsp;территории&nbsp;РФ, могу я&nbsp;участвовать?',
        answer: 'Нет, в&nbsp;акции могут принять участие дееспособные совершеннолетние граждане Российской Федерации, проживающие на&nbsp;территории Российской Федерации.'
    },
    {
        title: 'Какая продукция принимает участие в&nbsp;акции?',
        answer: 'Продукция реализуемая под товарным знаком Fresh Bar<sup class="reg">&reg;</sup>, участвующая в&nbsp;Акции:<br />\n' +
            '&#8470;\t\tНаименование <br />\n' +
            '1.\t\tFresh Bar COLA Original&nbsp;1,5л<br />\n' +
            '2.\t\tFresh Bar COLA Vanilla&nbsp;1,5л<br />\n' +
            '3.\t\tFresh Bar COLA Banana&nbsp;1,5л<br />\n' +
            '4.\t\tFresh Bar COLA Original&nbsp;0,45л ж/б<br />\n' +
            '5.\t\tFresh Bar COLA Vanilla&nbsp;0,45л ж/б<br />\n' +
            '6.\t\tFresh Bar COLA Banana&nbsp;0,45л ж/б<br />\n' +
            '7.\t\tFresh Bar COLA Original&nbsp;0,48л<br />\n' +
            '8.\t\tFresh Bar COLA Vanilla&nbsp;0,48л<br />\n' +
            '9.\t\tFresh Bar COLA Banana&nbsp;0,48л<br />\n' +
            'В&nbsp;Акции принимает участие в&nbsp;том числе специальным образом маркированная Продукция (с&nbsp;нанесением на&nbsp;упаковку информации об&nbsp;Акции) в&nbsp;промо-упаковке объемом 0,48л, 0,45л и&nbsp;1,5л.'
    },
    {
        title: 'До&nbsp;какого числа я&nbsp;могу зарегистрировать чек?',
        answer: 'Общий срок проведения акции&nbsp;&mdash; с&nbsp;01&nbsp;июня 2024 года по&nbsp;30&nbsp;октября 2024&nbsp;года.<br />\n' +
            'Сроки регистрации чеков на&nbsp;сайте с&nbsp;промо-упаковок продукции&nbsp;&mdash; с&nbsp;01&nbsp;июня 2024 года по&nbsp;31&nbsp;августа 2024&nbsp;года.'
    },
    {
        title: 'Из&nbsp;каких магазинов я&nbsp;могу зарегистрировать чек?',
        answer: 'Принимаются чеки из&nbsp;любых розничных и&nbsp;интернет-магазинов, кроме отмеченных в&nbsp;правилах акции.'
    },
    {
        title: 'Какие чеки принимаются для регистрации?',
        answer: 'Чек принимается в&nbsp;формате фотографии с&nbsp;четким изображением кассового чека и&nbsp;с&nbsp;обязательным наличием на&nbsp;нем таких пунктов, как: дата и&nbsp;время покупки, ИНН магазина, номер чека, название товаров, их&nbsp;количество и&nbsp;стоимость, читаемый&nbsp;QR код или читаемые поля ФН/ФД/ПД (фискальные данные).'
    },
    {
        title: 'Сколько времени требуется на&nbsp;модерацию чека?',
        answer: 'Модерация чеков занимает от&nbsp;24&nbsp;до&nbsp;72&nbsp;часов. После прохождения модерации статус по&nbsp;вашему чеку отобразиться в&nbsp;личном кабинете на&nbsp;сайте Акции.'
    },
    {
        title: 'Как мне узнать прошел&nbsp;ли мой чек модерацию?',
        answer: 'После завершения модерации статус по&nbsp;вашему чеку отобразиться в&nbsp;личном кабинете на&nbsp;сайте Акции.'
    },
    {
        title: 'Почему мой чек не&nbsp;прошел модерацию?',
        answer: 'Возможные причины, почему ваш чек не&nbsp;прошел модерацию:<br />\n' +
            '1. отсутствует продукт, участвующий в&nbsp;акции<br />\n' +
            '2. сроки покупки не&nbsp;соответствуют условиям акции (до&nbsp;01.05.2020 или после 31.05.2020)<br />\n' +
            '3. чек нечитаемый<br />\n' +
            '4. на&nbsp;чеке отсутствует вся или частично информация, такая как: дата и&nbsp;время покупки, ИНН магазина, номер чека, название товаров, их&nbsp;количество и&nbsp;стоимость, читаемый&nbsp;QR код или читаемые поля ФН/ФД/ПД (фискальные данные).<br />\n' +
            '5. чек загружен повторно.'
    },
    {
        title: 'Сколько позиций продукции, участвующей в&nbsp;акции, может быть в&nbsp;чеке?',
        answer: 'Один чек можно загрузить один раз для участия в&nbsp;акции. Ваш чек может содержать несколько позиций акционной продукции.'
    },
    {
        title: 'Почему мой чек не&nbsp;загружается?',
        answer: 'Пожалуйста, убедитесь что ваш чек соответствует техническим требованиям: формат JPG, PNG;разрешение минимальная ширина/высота 600&nbsp;пикселей; физический размер не&nbsp;более&nbsp;5 (пяти) мегабайт.'
    },
    {
        title: 'До&nbsp;какого числа осуществляется рассылка подарков?',
        answer: 'Период вручения подарков Акции&nbsp;&mdash; с&nbsp;&bdquo;01&ldquo; июня 2024&nbsp;г.&nbsp;по&nbsp;&bdquo;31&ldquo; августа 2024&nbsp;г.<br />\n' +
            'Период вручения призов Акции&nbsp;&mdash; с&nbsp;&bdquo;01&ldquo; июня 2024 года по&nbsp;&bdquo;31&ldquo; октября 2024&nbsp;года.<br />\n' +
            'Период вручения суперприза Акции и&nbsp;приза Конкурса&mdash; не&nbsp;позднее &bdquo;31&ldquo; октября 2024&nbsp;года.'
    },
    {
        title: 'Могу я&nbsp;получить подарок в&nbsp;денежном эквиваленте?',
        answer: 'Замена подарков денежным эквивалентом (выплата денежной компенсации) не&nbsp;производится.'
    },
    {
        title: 'Почему у&nbsp;меня запросили паспортные данные?',
        answer: 'Согласно Правилам Акции, организатор имеет право: <br />\n' +
            '1. проверить документы, удостоверяющие возраст и&nbsp;личность участника;<br />\n' +
            '2. запросить документы, удостоверяющие личность, ИНН и&nbsp;адрес доставки призов участника;<br />\n' +
            '3. в&nbsp;случае возникновения сомнений в&nbsp;подлинности предоставленных участником данных запросить у&nbsp;участника дополнительную информацию в&nbsp;виде отсканированной копии паспорта и&nbsp;документов, подтверждающих факт покупки продукции (копия чека и&nbsp;др.). <br />\n' +
            '<br />\n' +
            'Принимая участие в&nbsp;акции, Вы&nbsp;соглашаетесь с&nbsp;условиями Правил.'
    },
    {
        title: 'Что нужно сделать, чтобы принять участие в&nbsp;акции?',
        answer: 'Купите продукцию Fresh Bar<sup class="reg">&reg;</sup> Cola, зарегистрируйте чек на&nbsp;сайте www.freshbarcola.ru&nbsp;&mdash; получайте гарантированные подарки и&nbsp;возможность выиграть призы.'
    },
    {
        title: 'Сколько подарков я&nbsp;могу выиграть?',
        answer: 'Участник акции в&nbsp;течение периода проведения акции может получить не&nbsp;более&nbsp;3 (трех) гарантированных подарков в&nbsp;период с&nbsp;01.06.2024 года по&nbsp;31.08.2024&nbsp;года.'
    },
    {
        title: 'Необходимо&nbsp;ли заплатить налоги в&nbsp;случае получения приза?',
        answer: 'Призы в&nbsp;рамках конкурса облагаются НДФЛ, согласно&nbsp;п.&nbsp;6.6 Правил Акции, организатор выполняет функцию налогового агента, выплачивая НДФЛ за&nbsp;победителя акции.'
    },
    {
        title: 'Почему меня заблокировали/превышен лимит ввода чеков?',
        answer: 'Блокировка аккаунта участника возможна в&nbsp;следующих ситуациях:<br />\n' +
            '1. ввод&nbsp;5 (пяти) некорректных чеков подряд;<br />\n' +
            '2. повторный ввод&nbsp;5 (пяти) некорректных чеков подряд по&nbsp;истечении срока блокировки;<br />\n' +
            '3. попытка зарегистрировать более&nbsp;10 (десяти) чеков в&nbsp;неделю;<br />\n' +
            '4. подозрение в&nbsp;использовании участником специальных программ, скриптов, позволяющих выполнить подделку и&nbsp;загрузку недостоверных чеков, упростить их&nbsp;регистрацию в&nbsp;программе;<br />\n' +
            '5. ввод количества чеков, превышающего разрешенный объем, в&nbsp;короткий промежуток времени&nbsp;&mdash; более&nbsp;5 (пяти) чеков в&nbsp;минуту.'
    },
    {
        title: 'Почему при регистрации необходимо указывать адрес электронной почты?',
        answer: 'При регистрации на&nbsp;сайте участнику направляется письмо на&nbsp;указанный им&nbsp;адрес электронной почты. Письмо содержит ссылку, по&nbsp;которой необходимо перейти, нажав на&nbsp;нее, для завершения процедуры регистрации.'
    },
    {
        title: 'Мне не&nbsp;пришло письмо об&nbsp;авторизации.',
        answer: 'Проверьте папку &laquo;Спам&nbsp;/ Нежелательная почта&raquo;. Если письмо отсутствует, обратитесь в&nbsp;раздел &laquo;Обратная связь&raquo; на&nbsp;сайте промоакции с&nbsp;указанием электронного адреса, который&nbsp;Вы использовали при регистрации.'
    },
    {
        title: 'Зачем указывать адрес в&nbsp;Личном кабинете на&nbsp;сайте акции?',
        answer: 'Отправка подарка будет совершена на&nbsp;адрес победителя, указанный им&nbsp;в&nbsp;Личном кабинете на&nbsp;Сайте'
    },
    {
        title: 'Как мне зарегистрировать чек?',
        answer: 'Чтобы зарегистрировать чек, вам необходимо пройти регистрацию или авторизоваться на&nbsp;сайте акции. После этого вам необходимо отсканировать чек в&nbsp;специальном окне или ввести данные чека вручную. Ваш чек будет отправлен на&nbsp;модерацию.'
    },
    {
        title: 'Сколько чеков я&nbsp;могу зарегистрировать?',
        answer: 'За&nbsp;весь период акции один участник акции может зарегистрировать не&nbsp;более&nbsp;90 (девяносто) чеков. В&nbsp;неделю один участник акции может зарегистрировать максимум&nbsp;10 (десять) чеков. При попытке ввода 11-го (одиннадцатого) чека участник акции будет проинформирован о&nbsp;превышении лимита ввода чеков с&nbsp;указанием даты, с&nbsp;которой участник акции может возобновить регистрацию чеков.'
    },
    {
        title: 'Как принять участие в&nbsp;Творческом конкурсе',
        answer: 'В&nbsp;период с&nbsp;01&nbsp;июня 2024 по&nbsp;15&nbsp;июля 2024&nbsp;вы можете принять участие в&nbsp;Творческом конкурсе в&nbsp;группе&nbsp;ВК https://vk.com/freshbar?from=search:<br />\n' +
            '1. Зарегистрироваться или авторизоваться в&nbsp;социальной сети Вконтакте<br />\n' +
            '2. Вступить или быть участником группы Fresh Bar https://vk.com/freshbar?from=searchапитки (vk.com)<br />\n' +
            '3. Найти в&nbsp;группе Fresh Bar пост-приглашение принять участие в&nbsp;творческом конкурсе от&nbsp;Вали Карнавал.<br />\n' +
            '4. Оставить комментарий согласно задаче, указанной в&nbsp;посте в&nbsp;период приема заявок на&nbsp;участие в&nbsp;Творческом конкурсе.<br />\n' +
            '5. Страница Участника должна быть в&nbsp;открытом доступе в&nbsp;течение всего периода Конкурса.'
    },
    {
        title: 'Как определяется победитель творческого конкурса?',
        answer: 'Все конкурсные работы проходят модерацию на&nbsp;нарушение положений Правил Акции. Все конкурсные работы прошедшие модерацию будут рассмотрены конкурсным жюри. Жюри определяет победителя конкурса.'
    },
    {
        title: 'Каким требованиям должна соответствовать конкурсная работа?',
        answer: 'Требования к&nbsp;Конкурсной работе (комментарию):<br />\n' +
            '1. Комментарий должен иметь рифму к&nbsp;предыдущему комментарию;<br />\n' +
            '2. Комментарий должен состоять минимум из&nbsp;двух строк;<br />\n' +
            '3. В&nbsp;строках отсутствуют упоминания продуктов брендов любых компаний, кроме Заказчика Акции;<br />\n' +
            '4. Строки должны быть эмоциональными, приносящими радость;<br />\n' +
            '5. Строки не&nbsp;должны иметь призыв к&nbsp;действиям, нарушающим законодательство Российской Федерации и&nbsp;нормы морали, принятые в&nbsp;обществе;<br />\n' +
            '6. Строки не&nbsp;должны содержать политической, религиозной тематики, а&nbsp;также непристойных и&nbsp;оскорбительных образов\\выражений, в&nbsp;том числе в&nbsp;отношении пола, расы, национальности, профессии, социальной категории, возраста, языка человека и&nbsp;гражданина, официальных государственных символов (флагов, гербов, гимнов), религиозных символов, объектов культурного наследия (памятников истории и&nbsp;культуры);<br />\n' +
            '7. Строки не&nbsp;должны содержать ненормативную и&nbsp;нецензурную лексику;<br />\n' +
            '8. Не&nbsp;допускается размещение одинаковых (повторных) строк в&nbsp;рамках участия в&nbsp;творческом конкурсе.'
    }
];

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Вопрос о продукте',
        id: 6
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Home() {
    const [mode, setMode] = useState('left');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [faqCur, setFaqCur] = useState(0);
    const [searchLoading, setSearchLoading] = useState(false);
    const {profile} =  useProfile();
    const [loading, setLoading] = useState(false);
    const [week, setWeek] = useState(1);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winners, setWinners] = useState([]);
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);
    const [isWeekDropdownOpen, setIsWeekDropdownOpen] = useState(false);

    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const schema2 = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        message: yup.string().min(6, 'Мин. 6 символов').required('Обязательно к заполнению'),
        theme: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие')
    }).required();

    const {
        register: register2,
        reset: reset2,
        setValue: setValue2,
        clearErrors: clearErrors2,
        watch: watch2,
        setFocus: setFocus2,
        setError: setError2,
        formState: {
            errors: errors2
        },
        handleSubmit: handleSubmit2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const watchEmail = watch("email");

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        const formData = new FormData();
        formData.append("term", data.email);

        await fetch(process.env.REACT_APP_API_PROXY + 'searchWinner', {
            method: "POST",
            body: formData,
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                if(response.data){
                    setWinners(response.data);
                    setWinPaging({
                        total: 1,
                        current_page: 1
                    });
                }else{
                    setWinners([]);
                    setWinPaging({
                        total: 1,
                        current_page: 1
                    });
                }
                setWeek(0);
                setLoading(false);
                setWinnersEmpty('Среди победителей данный аккаунт не найден');
            }else{
                if(response.error && response.error.email){
                    setError('email', { type: 'custom', message: response.error.email[0] });
                }
            }

            setSearchLoading(false);
        });
    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.message);
        formData.append("subject_id", data.theme);
        formData.append("accept1", 1);

        if(fileInput && fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        let headers = {
            Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
        }

        if(localStorage.getItem('auth_key')){
            headers = {
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }

        await fetch(process.env.REACT_APP_API_PROXY + 'sendFeedback', {
            method: "POST",
            body: formData,
            headers: headers
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                reset2();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    title="Спасибо за&nbsp;обращение"
                    text="Ваш вопрос будет обработан в&nbsp;течение 72&nbsp;часов. Ответ будет отправлен на&nbsp;указанный электронный адрес"
                    close={hide}/>, "modal--info");

                if(profile && profile.name){
                    setValue('name', profile.name);
                }

                if(profile && profile.email){
                    setValue('email', profile.email);
                }
            }else{
                if(response.error.email){
                    setError('email', { type: 'custom', message: response.error.email[0] });
                }

                if(response.error.name){
                    setError('name', { type: 'custom', message: response.error.name[0] });
                }

                if(response.error.text){
                    setError('message', { type: 'custom', message: response.error.text[0] });
                }
            }

            setIsSubmitting(false);
        });
    };

    const { show, hide} = useModal();

    const [curSl1, setCurSl1] = useState(0);
    const [loadedSl1, setLoadedSl1] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        breakpoints: {
            "(max-width: 1023px)": {
                slides: { perView: 1, spacing: 0 },
            },
        },
        initial: 0,
        loop: true,
        slides: {
            perView: 4,
            spacing: 0,
        },
        slideChanged(slider) {
            setCurSl1(slider.track.details.rel)
        },
        created() {
            setLoadedSl1(true)
        },
    });

    const findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');

            let email = findGetParameter('email');

            let identity_vk = findGetParameter('identity_vk');
            let identity_ok = findGetParameter('identity_ok');
            let identity_go = findGetParameter('identity_go');
            let identity_ya = findGetParameter('identity_ya');
            let identity_ma = findGetParameter('identity_ma');
            let network = findGetParameter('network');
            let first = findGetParameter('first');
            let click_id = findGetParameter('click_id');

            const url = new URL(window.location.href);

            if(identity_vk !== null && identity_vk !== '' && identity_vk !== undefined){
                localStorage.setItem('identity_vk', identity_vk);
            }

            if(identity_ok !== null && identity_ok !== '' && identity_ok !== undefined){
                localStorage.setItem('identity_ok', identity_ok);
            }

            if(identity_go !== null && identity_go !== '' && identity_go !== undefined){
                localStorage.setItem('identity_go', identity_go);
            }

            if(identity_ya !== null && identity_ya !== '' && identity_ya !== undefined){
                localStorage.setItem('identity_ya', identity_ya);
            }

            if(identity_ma !== null && identity_ma !== '' && identity_ma !== undefined){
                localStorage.setItem('identity_ma', identity_ma);
            }

            if(network && first == 1){
                localStorage.setItem('network', network);
            }

            if(click_id){
                localStorage.setItem('click_id', click_id);
            }

            if(parseInt(result) === 1 && auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                window.location.href = process.env.PUBLIC_URL + '/cabinet';
            }

            if(parseInt(result) === 0){
                window.history.pushState({param: 'Value'}, '', '/');
                show(<Reg token={auth_key} email={email} soc={true}/>, "modal--form modal--reg");
            }
        }

        if(window.location.hash){
            setTimeout(()=>{
                goToAnchor(window.location.hash.substring(1));
                window.history.pushState({param: 'Value'}, '', '/');
            }, 500);
        }
    },[]);

    useEffect(()=>{
        if(week > 0){
            setValue('email', '');
            getWinners(1);
        }
    }, [week]);

    const getWinners = async(page = 1) => {
        setLoading(true);
        let query = process.env.REACT_APP_API_PROXY + 'getWinners?page=' + page + '&week=' + week;
        await fetch(query, {
            method: "GET",
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setWinners(response.data.rows);
                setWinPaging(response.data.paging);
                setLoading(false);
                if(page !== 1){
                    goToAnchor('winners');
                }
            }
        });
    }

    const pagination = (c, m) => {
        let current = c,
            last = m,
            delta = 1,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 24L11.5 15.5L19.5 7" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    getWinners(parseInt(page))
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 24L21.5 15.5L13.5 7" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    const renderMessageField = () => {
        return(
            <div className="col-6 form__it">
                <div className={'form__field form__field--textarea' + (errors2.message ? ' has-error' : '')}>
                    <textarea
                        id="message"
                        placeholder="Текст сообщения"
                        {...register2("message")}
                    />
                    {errors2.message && (
                        <p className="error__message">
                            {errors2.message?.message}
                        </p>
                    )}
                </div>
            </div>
        )
    }

    return (
        <ScrollPercentage triggerOnce={true} onChange={(percentage) => {
            if(!depths['25'] && percentage >= 0.25) {
                if(window.ym) {
                    window.ym(97094979,'reachGoal', 'Scroll25_1экр');
                }
                depths['25'] = true;
            }

            if(!depths['50'] && percentage >= 0.5) {
                if(window.ym) {
                    window.ym(97094979,'reachGoal', 'Scroll50_1экр');
                }
                depths['50'] = true;
            }

            if(!depths['75'] && percentage >= 0.75) {
                if(window.ym) {
                    window.ym(97094979,'reachGoal', 'Scroll75_1экр');
                }
                depths['75'] = true;
            }

            if(!depths['100'] && percentage >= 0.83) {
                if(window.ym) {
                    window.ym(97094979,'reachGoal', 'Scroll100_1экр');
                }
                depths['100'] = true;
            }

            if(!depths['products'] && percentage >= 0.45) {
                if(window.ym) {
                    window.ym(97094979,'reachGoal', 'Scroll_продукты_1экр');
                }
                depths['products'] = true;
            }
        }}>
            <Header/>
            <div id="promo" className="content content--promo">
                <div className="sc sc__promo">
                    <div className="promo__ct">
                        <div className="promo__title promo--anim bounceInDown animated">
                            <img src={require("../assets/img/promo-title.svg").default}/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <CodesWidget extraClasses="btn-ct--filled btn-ct--red btn-ct--large btn--flash"/>
                        </div>
                    </div>
                    <div className="promo__img">
                        <img src={require("../assets/img/promo-img.png")}/>
                    </div>
                    <div className="desk-only promo__product promo--anim bounceInUp animated">
                        <img src={require("../assets/img/promo-product.png")}/>
                    </div>
                </div>
                <div className="sc sc__steps">
                    <div id="steps" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Как участвовать
                            </div>
                        </div>
                        <div className="steps__list">
                            <div className="row">
                                <div className="col-4 steps__it">
                                    <div className="steps__it-bl d-flex align-items-start">
                                        <div className="steps__it-numb">
                                            <img src={require("../assets/img/numb-1.svg").default}/>
                                        </div>
                                        <div className="steps__it-title">
                                            <span>ПОКУПАЙ</span> <br/>
                                            Fresh Bar COLA <br/>
                                            Original, Banana <br/>
                                            или Vanilla
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 steps__it">
                                    <div className="steps__it-bl d-flex align-items-start">
                                        <div className="steps__it-numb">
                                            <img src={require("../assets/img/numb-2.svg").default}/>
                                        </div>
                                        <div className="steps__it-title">
                                            <span>Регистрируй</span> <br/>
                                            чеки на сайте
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 steps__it">
                                    <div className="steps__it-bl d-flex align-items-start">
                                        <div className="steps__it-numb">
                                            <img src={require("../assets/img/numb-3.svg").default}/>
                                        </div>
                                        <div className="steps__it-title">
                                            <span>Выигрывай</span> <br/>
                                            призы <br/>
                                            не по правилам
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center steps__action">
                            <CodesWidget extraClasses="btn-ct--filled btn-ct--white btn-ct--large"/>
                        </div>
                        <div className="text-center text">
                            <a onClick={()=>{
                                if(window.ym) {
                                    window.ym(97094979,'reachGoal', 'Клик по кнопке правила акции');
                                }
                            }} href="/rules.pdf" target="_blank">
                                Подробные правила акции
                            </a>
                        </div>
                    </div>
                    <div className="loop__line loop__line--1"/>
                </div>
                <div id="prizes" className="sc sc__prizes">
                    {/*<div className="decor decor--7"/>*/}
                    <div className="decor decor--8"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Призы
                            </div>
                        </div>
                        <div className="prizes__list">
                            <div className="row">
                                <div className="col col-4 prizes__it">
                                    <div className="prizes__it-img">
                                        <div className="prize__img prize__img--1">
                                            <img src={require("../assets/img/pr-1.png")}/>
                                        </div>
                                    </div>
                                    <div className="prizes__it-lbl">
                                        гарантированно
                                    </div>
                                    <div className="prizes__it-title">
                                        Стикер-пак под <br/>
                                        вкус колы
                                    </div>
                                </div>
                                <div className="col col-4 prizes__it">
                                    <div className="prizes__it-img">
                                        <div className="prize__img prize__img--2">
                                            <img src={require("../assets/img/pr-2.png")}/>
                                        </div>
                                    </div>
                                    <div className="prizes__it-lbl">
                                        каждую неделю
                                    </div>
                                    <div className="prizes__it-title">
                                        Много яркого <br/>
                                        мерча
                                    </div>
                                </div>
                                <div className="col col-4 prizes__it prizes__it--main">
                                    <div className="prizes__it-img">
                                        <div className="prize__img prize__img--3">
                                            <img src={require("../assets/img/pr-3.png")}/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="prizes__it-lbl">
                                            главный приз
                                        </div>
                                    </div>
                                    <div className="prizes__it-title">
                                        <span>Не по правилам</span> <br/>
                                        много <span>денег</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loop__line loop__line--2"/>
                <div id="products" className="sc sc__products">
                    <div className="decor decor--9"/>
                    <div className="decor decor--9-1"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Продукты
                            </div>
                        </div>
                        <div className="products__sl">
                            <div onClick={()=>{
                                instanceRef.current?.prev();
                            }}  className="sl__nav sl__nav--prev">
                                <img src={require("../assets/img/left.svg").default}/>
                            </div>
                            <div onClick={()=>{
                                instanceRef.current?.next();
                            }}  className="sl__nav sl__nav--next">
                                <img src={require("../assets/img/right.svg").default}/>
                            </div>
                            <div className="products__sl-row">
                                <div ref={sliderRef} className="keen-slider">
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--1">
                                                <img src={require("../assets/img/prod-1.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Original <br/>
                                                1.5 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--2">
                                                <img src={require("../assets/img/prod-2.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Original <br/>
                                                0.48 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--3">
                                                <img src={require("../assets/img/prod-3.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Original <br/>
                                                0.45 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--4">
                                                <img src={require("../assets/img/prod-4.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Vanilla <br/>
                                                1.5 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--5">
                                                <img src={require("../assets/img/prod-5.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Vanilla <br/>
                                                0.48 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--6">
                                                <img src={require("../assets/img/prod-6.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Vanilla <br/>
                                                0.45 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--7">
                                                <img src={require("../assets/img/prod-7.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Banana <br/>
                                                1.5 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--8">
                                                <img src={require("../assets/img/prod-8.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Banana <br/>
                                                0.48 л.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="products__it">
                                            <div className="products__it-img prod--9">
                                                <img src={require("../assets/img/prod-9.png")}/>
                                            </div>
                                            <div className="products__it-lbl text-center">
                                                fresh bar Cola
                                            </div>
                                            <div className="products__it-title text-center">
                                                Banana <br/>
                                                0.45 л.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="loop__line loop__line--3"/>
                <div id="contest" className="sc sc__contest">
                    <div className="decor decor--10"/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col col-7">
                                <div className="contest__img">
                                    <img src={require("../assets/img/contest-img.png")}/>
                                </div>
                            </div>
                            <div className="col col-5">
                                <div className="sc__header">
                                    <div className="sc__title">
                                        Участвуй <br/>
                                        в конкурсе
                                    </div>
                                    <div className="sc__subtitle">
                                        Придумай фреш-кольные строчки <br/>
                                        и получи топовые беспроводные наушники!
                                    </div>
                                    <div className="d-flex">
                                        <div onClick={()=>{
                                            show(<Contest/>, "modal--form modal--contest");
                                            if(window.ym) {
                                                window.ym(97094979,'reachGoal', 'Клик по кнопке Информация о конкурсе');
                                            }
                                        }} className="btn-ct btn-ct--filled btn-ct--red btn-ct--large d-flex align-items-center justify-content-center">
                                            Подробнее
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc sc__winners">
                    <div id="winners" className="anchor"/>
                    <div className="bg-top"/>
                    <div className="bg-bottom"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Победители
                            </div>
                        </div>
                        <div className="win__form-box d-flex align-items-center justify-content-center">
                            <form className={'win__search' + (searchLoading ? ' form--loading' : '') + (errors.email ? ' has-error' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                <div className={'win__search-field' + (errors.email ? ' has-error' : '')}>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        id="email"
                                        placeholder="Поиск по e-mail"
                                        {...register("email")}
                                    />
                                </div>
                                {errors.email && (
                                    <p className="error__message">
                                        {errors.email?.message}
                                    </p>
                                )}
                                <button type="submit" className="btn__search d-flex align-items-center justify-content-center">
                                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.93738" cy="8.36011" r="6.59633" stroke="white" strokeWidth="1.99436"/>
                                        <line x1="12.3094" y1="12.7989" x2="16.2037" y2="16.6933" stroke="white" strokeWidth="1.99436"/>
                                    </svg>
                                </button>
                            </form>

                            <div className={'form__select has-value' + (isWeekDropdownOpen ? ' active' : '')}>
                                <div onClick={()=>{
                                    setIsWeekDropdownOpen(!isWeekDropdownOpen);
                                }} className="form__select-input d-flex align-items-center justify-content-between">
                                    <div className="form__select-value">
                                        {week > 0 ? 'Неделя ' + week : 'Выбрать неделю'}
                                    </div>
                                    <div className="form__select-toggle">
                                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.1579 1.06519L7.31599 7.63525L1.47412 1.06519" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="form__select-dropdown">
                                    <div className="form__dropdown-list">
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((it, i) => (
                                            <div onClick={()=>{
                                                setIsWeekDropdownOpen(false);
                                                setWeek(it);
                                            }} className={'form__dropdown-it' + (it == week ? ' active' : '')}>
                                                Неделя {it}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="win__tb-wrap">
                            {winners.length > 0 && (
                                <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                    <div className="tb__body">
                                        {winners.length > 0 && winners.map((win, i) => (
                                            <div className="tb__row">
                                                <div className="tb__col" data-title="Имя">
                                                    <div className="tb__td">
                                                        {win.name ?? '-'}
                                                    </div>
                                                </div>
                                                <div className="tb__col" data-title="Телефон">
                                                    <div className="tb__td">
                                                        {win.email ?? '-'}
                                                    </div>
                                                </div>
                                                <div className="tb__col" data-title="Дата">
                                                    <div className="tb__td">
                                                        {win.date}
                                                    </div>
                                                </div>
                                                <div className="tb__col" data-title="Приз">
                                                    <div className="tb__td">
                                                        {win.prize}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {winners.length === 0 && (
                                <div className={'tb__empty d-flex align-items-center justify-content-center'  + (loading ? ' tb--loading' : '')}>
                                    <div className="tb__empty-title">
                                        {winnersEmpty}
                                    </div>
                                </div>
                            )}
                            {winPaging.total > 1 && (
                                renderPager()
                            )}
                        </div>
                    </div>
                </div>
                <div id="faq" className="sc sc__faq">
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title text-center">
                                Вопросы-ответы
                            </div>
                        </div>
                        <div className="faq__wrap">
                            <div className="faq__scroll">
                                <CustomScroll heightRelativeToParent="100%" allowOuterScroll="true">
                                    <div className="faq__list">
                                        {faq.map((fq, i) => (
                                            <div onClick={()=>{
                                                if(faqCur === (i + 1)) {
                                                    setFaqCur(-1);
                                                }else {
                                                    setFaqCur(i + 1);
                                                    if(window.ym) {
                                                        window.ym(97094979,'reachGoal', 'Переход в раздел вопрос-ответ');
                                                    }
                                                }
                                            }} className={'faq__it fadeIn animated' + (faqCur == (i + 1) ? ' active' : '') + (i > 4 ? ' faq__it--hidden' : '')}>
                                                <div className="faq__it-hd d-flex align-items-center">
                                                    <div className="faq__it-icon">
                                                        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="40.4607" cy="40.2966" r="40.2969" fill="#EE2323"/>
                                                            <line x1="39.9607" y1="25.8562" x2="39.9607" y2="54.7371" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                                            <line x1="26.0203" y1="39.7966" x2="54.9011" y2="39.7966" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                                        </svg>
                                                    </div>
                                                    <div className="faq__it-title">
                                                        {parse(fq.title)}
                                                    </div>
                                                </div>
                                                <div className="faq__it-answer fadeIn animated">
                                                    <div className="faq__it-txt">
                                                        {parse(fq.answer)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CustomScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="feedback" className="sc sc__feedback">
                    <div className="feedback-bg"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Обратная связь
                            </div>
                        </div>
                        <div className="feedback__wrap">
                            <form onSubmit={handleSubmit2(onSubmit)} className={'feedback__form' + (isSubmitting ? ' form--loading' : '')}>
                                <div className="form__list">
                                    <div className="row">
                                        <div className="col-6 form__it">
                                            <div className={'form__field' + (errors2.email ? ' has-error' : '')}>
                                                <input
                                                    autoComplete="username"
                                                    type="text"
                                                    id="email"
                                                    placeholder="Ваш e-mail"
                                                    {...register2("email")}
                                                />
                                                {errors2.email && (
                                                    <p className="error__message">
                                                        {errors2.email?.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6 form__it">
                                            <div className={'form__field' + (errors2.name ? ' has-error' : '')}>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    placeholder="Ваше имя"
                                                    {...register2("name")}
                                                />
                                                {errors2.name && (
                                                    <p className="error__message">
                                                        {errors2.name?.message}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                        <div className="col-6 form__it">
                                            <div className="form__it-box">
                                                <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors2.theme ? ' has-error' : '')}>
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                                    }} className="form__select-input d-flex align-items-center justify-content-between">
                                                        <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                            {theme != '' ? themeTitle : 'Тема сообщения'}
                                                        </div>
                                                        <div className="form__select-toggle">
                                                            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="27.833" cy="28.4263" r="27.7512" fill="white"/>
                                                                <path d="M37.0837 25.2241L27.8328 35.6282L18.5818 25.2241" stroke="#EE2323" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="form__select-dropdown">
                                                        <div className="form__dropdown-list">
                                                            {topics.map((it, i) => (
                                                                <div onClick={()=>{
                                                                    setIsThemeDropdownOpen(false);
                                                                    setThemeTitle(it.title);
                                                                    setTheme(it.id);
                                                                    setValue2('theme', it.id);
                                                                    clearErrors2('theme');
                                                                }} className={'form__dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                                    {it.title}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors2.theme && (
                                                    <p className="error__message">
                                                        {errors2.theme?.message}
                                                    </p>
                                                )}
                                            </div>

                                            {window.matchMedia("(max-width: 1023px)").matches && renderMessageField()}

                                            <div className="form__it-box d-flex align-items-center">
                                                <label className="d-flex align-items-center justify-content-center form__attach-btn">
                                                    <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                                        const fileInput = document.querySelector('#file') ;
                                                        const files = event.target.files;
                                                        const fileName = files[0].name;
                                                        setAttachFile(fileName);
                                                    }}/>
                                                    <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                                        <i className="icon-file">
                                                            <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="21.2745" cy="20.8204" r="20.7682" fill="white"/>
                                                                <path d="M17.1924 26.6273L25.0824 19.0743C25.3067 18.8636 25.4854 18.6092 25.6075 18.3268C25.7297 18.0444 25.7927 17.74 25.7927 17.4323C25.7927 17.1246 25.7297 16.8202 25.6075 16.5378C25.4854 16.2554 25.3067 16.001 25.0824 15.7903C24.6182 15.3536 24.0048 15.1104 23.3674 15.1104C22.73 15.1104 22.1167 15.3536 21.6524 15.7903L13.8184 23.2883C13.3922 23.6886 13.0525 24.1719 12.8203 24.7085C12.5881 25.2451 12.4683 25.8236 12.4683 26.4083C12.4683 26.993 12.5881 27.5715 12.8203 28.1081C13.0525 28.6447 13.3922 29.1281 13.8184 29.5283C15.6184 31.2513 18.5364 31.2513 20.3364 29.5283L28.2854 21.9203C30.9374 19.3803 30.9374 15.2643 28.2854 12.7243C25.6324 10.1853 21.3314 10.1853 18.6784 12.7243L12.2744 18.8543" stroke="#EE2323" strokeWidth="1.5" strokeLinecap="round"/>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <span>{attachFile ? attachFile : 'Прикрепить изображение'}</span>
                                                </label>
                                                {attachFile != '' && (
                                                    <div onClick={()=>{
                                                        setValue('attachment', null);
                                                        setAttachFile('');
                                                    }} className="file__clear">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.34375 6.17456L15.5937 15.4246" stroke="#FFFFFF" stroke-width="2"/>
                                                            <path d="M15.5938 6.17456L6.34375 15.4246" stroke="#FFFFFF" stroke-width="2"/>
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="form__it-box">
                                                <div className={'checkbox__wrap' + (errors2.agree ? ' has-error' : '')}>
                                                    <label className="align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            {...register2("agree")}
                                                        />
                                                        <div className="checkbox__decor"/>
                                                        <div className="checkbox__title text-left">
                                                            Я согласен на обработку <a className="white" href="/data_processing_agreement.pdf" target="_blank">персональных данных</a>
                                                        </div>
                                                    </label>
                                                    {errors2.agree && (
                                                        <p className="error__message">
                                                            {errors2.agree?.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {window.matchMedia("(min-width: 1024px)").matches && renderMessageField()}
                                        <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                            <button onClick={()=>{
                                                if(window.ym) {
                                                    window.ym(97094979,'reachGoal', 'Клик по кнопке Обратная связь');
                                                }
                                            }} type="submit" className="btn-ct btn-ct--filled btn-ct--large btn-ct--blue d-flex align-items-center justify-content-center">
                                                <span>Отправить</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </ScrollPercentage>
    );
}

export default Home;
